<template>
  <div class="ag-cell-list-item clinician-schedules-list">
    <a
      class="ag-cell-click center-vertical"
      @click="showDetail"
    >
      <span class="td-time">
        <span>{{ timeValue }}</span>
        <span class="am-pm">{{ meridiemValue }}</span>
      </span>
    </a>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'DateTimeCellRenderer',
  mixins: [DateTimeHelper],
  data () {
    return {
      timeValue: '',
      meridiemValue: ''
    }
  },
  beforeMount () {
    const dateTime = `${this.params.data.visit_date} ${this.params.data.visit_time}`
    this.timeValue = this.$toLocal(dateTime, 'hh:mm')
    this.meridiemValue = this.$toLocal(dateTime, 'A')
  },
  methods: {
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
