import { mapGetters, mapMutations } from 'vuex'
import { SET_SCROLL_POSITION_BY_TAB } from '@/store/modules/user/constants.js'

export const GridHelpers = {
  data () {
    return {
      targetElement: null,
      scrollCount: 0
    }
  },
  computed: {
    ...mapGetters({
      savedScrollPosition: 'getSavedScrollPositionByTab'
    }),
    scrollBox () {
      return this.homeTab === 'week' ? document.querySelector('.clinician-schedules-list-container') : document.querySelector('[ref="eBodyViewport"]')
    },
    scrollPosition () {
      const key = this.savedScrollPosition.find(i => i.tab === this.homeTab)
      return key && key.index ? key.index : 0
    }
  },
  methods: {
    ...mapMutations({
      setScrollPosition: SET_SCROLL_POSITION_BY_TAB
    }),
    showTooltip (data) {
      const colId = data.colId
      const rowId = data.nodeId

      // target element is where we're putting the tooltip contents
      this.targetElement = this.getTargetElement(data)

      // get the tooltip contents from the tooltip component div
      const rowElement = `ag-tooltip-${data.gridId}-${colId}-${rowId}`
      this.tooltipContent = document.getElementById(rowElement).innerHTML

      // determine the position of the tooltip based on the clicked cell
      const sourceCellSelectorString = `data-cell-${data.gridId}-${colId}-${rowId}`
      const sourceCell = document.getElementById(sourceCellSelectorString).parentElement
      const targetPosition = this.getTargetPosition(sourceCell)

      // populate the target element with tooltip contents and add
      this.targetElement.innerHTML = this.tooltipContent
      this.targetElement.classList.add('visible')
      // set the tooltip position: below clicked cell, or, if cell position is within 200px of window bottom, above clicked cell
      this.tooltipPosition = { top: targetPosition.bottom - 50 + 'px', left: targetPosition.left + 10 + 'px' }
      if (targetPosition.bottom >= (window.innerHeight - 200)) {
        this.tooltipPosition = { bottom: window.innerHeight - targetPosition.top - 15 + 'px', left: targetPosition.left + 'px' }
      }
    },
    hideTooltip () {
      const targetElement = document.querySelectorAll('.ag-tooltip-container')
      targetElement.forEach(item => {
        item.classList.remove('visible')
      })

      Object.entries(this.$refs).forEach(vm => {
        vm.isTooltipVisible = false
        vm.tooltipContent = ''
        vm.tooltipCell = {}
      })
    },
    getTargetElement (data) {
      return document.getElementById(data.gridId).querySelector('.ag-tooltip-container')
    },
    getTargetPosition (el) {
      const position = el.getBoundingClientRect()
      return { left: position.left + window.scrollX, top: position.top - window.scrollY, bottom: position.bottom + window.scrollY }
    },
    openTooltip (data) {
      this.hideTooltip()
      if (Object.keys(this.tooltipCell).length) {
        if (this.isTooltipVisible === true && data.gridId === this.tooltipCell.gridId && data.colId === this.tooltipCell.colId && data.nodeId === this.tooltipCell.nodeId) {
          this.isTooltipVisible = false
          this.tooltipCell = {}
          this.hideTooltip()
        } else {
          this.setToolTipVisible(data)
        }
      } else {
        this.setToolTipVisible(data)
      }
    },
    setToolTipVisible (data) {
      this.tooltipCell.gridId = data.gridId
      this.tooltipCell.colId = data.colId
      this.tooltipCell.nodeId = data.nodeId
      this.isTooltipVisible = true
      this.showTooltip(data)
    },
    externalFilterChanged () {
      this.$emit('close')
      if (this.gridApi) {
        this.gridApi.onFilterChanged()
      }
    },
    isExternalFilterPresent () {
      return this.searchString.length > 1
    },
    doesExternalFilterPass (node) {
      /**
       * This is the text search filter.
       * For each field/column defined in the haystack, it looks for this.searchString
      */
      const haystack = [
        node.data.access_control_id,
        node.data.first_name,
        node.data.last_name,
        `${node.data.first_name} ${node.data.last_name}`,
        node.data.mrn
      ]
      const match = haystack.findIndex(element => element.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) > -1

      if (match === true) {
        this.validSearchResult = true
      }
      return match
    },
    headerHeightGetter () {
      const columnHeaderTexts = [
        ...document.querySelectorAll('.ag-header-cell-text')
      ]
      const clientHeights = columnHeaderTexts.map(
        headerText => headerText.clientHeight
      )
      const tallestHeaderTextHeight = Math.max(...clientHeights)

      return tallestHeaderTextHeight
    },
    headerHeightSetter () {
      const padding = 10
      const height = this.headerHeightGetter() + padding
      this.gridOptions.api.setHeaderHeight(height)
    },
    scrollToPosition () {
      if (this.scrollPosition && this.scrollPosition > 0) {
        this.scrollBox.scrollTo(0, this.scrollPosition)
      }
    },

    handleScrollEvent () {
      this.hideTooltip()
    },
    getGridHeight (offset) {
      const offsetHeight = offset || 215
      const height = document.documentElement.clientHeight - offsetHeight
      return `width: 865px; height: ${height}px;`
    },
    scrollToRow () {
      if (this.scrollPosition !== 0) {
        this.gridApi.ensureIndexVisible(this.scrollPosition, 'top')
      }
    },
    resetScrollPosition (tab) {
      this.setScrollPosition({ tab: tab, index: 0 })
    }
  }
}
