<template>
  <div class="clinician-schedules-list by-day">
    <ClinicianSchedulesListSingleDay
      :id="getDateId()"
      ref="singleDayAppointments"
      :date="date"
      :appointments="appointmentData"
      :show-selector="true"
      :hide-back="isInRange"
      :hide-today="isInRange"
      :set-max-height="true"
      @reset="reset"
      @goBackwards="goBackwards"
      @goForward="goForward"
      @openLastSessionDetail="patient => this.$emit('openLastSessionDetail', patient)"
      @close="closeLastSessionDetail"
    />
  </div>
</template>

<script>
import ClinicianSchedulesListSingleDay from '@/components/homepage/schedules/ClinicianSchedulesListSingleDay'
import { AppointmentsByDate } from '@/mixins/AppointmentsByDate'

export default {
  name: 'ClinicianSchedulesListByDay',
  components: {
    ClinicianSchedulesListSingleDay
  },
  mixins: [AppointmentsByDate],
  props: {
    appointmentData: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: () => {
    return {
      appointmentView: true
    }
  },
  methods: {
    resetGrid () {
      this.$refs.singleDayAppointments.resetGrid()
    },
    closeLastSessionDetail (params) {
      this.$emit('close', params)
    }
  }
}
</script>
<style lang="scss">
.body-clinician-layout {
  .clinician-schedules-list {
    height: 100%;
    padding-bottom: 16px;
    &.by-day {
      background-color: #fff;
      .alert {
        margin-bottom: 0;
      }
    }
  }
}
</style>
