// import store from '@/store'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import AvatarCellRenderer from '@/components/homepage/cellRenderers/AvatarCellRenderer.vue'
import DateTimeCellRenderer from '@/components/homepage/cellRenderers/DateTimeCellRenderer.vue'
import NameCellRenderer from '@/components/homepage/cellRenderers/NameCellRenderer.vue'
import ClinicianTeamsCellRenderer from '@/components/homepage/cellRenderers/ClinicianTeamsCellRenderer.vue'
import NextSessionDueCellRenderer from '@/components/homepage/cellRenderers/NextSessionDueCellRenderer.vue'
import LatestCompletedSessionCellRenderer from '@/components/homepage/cellRenderers/LatestCompletedSessionCellRenderer.vue'

export const PatientsByDueDate = {
  components: {
    AvatarCellRenderer,
    DateTimeCellRenderer,
    NameCellRenderer,
    ClinicianTeamsCellRenderer,
    NextSessionDueCellRenderer,
    LatestCompletedSessionCellRenderer
  },
  mixins: [DateTimeHelper],
  computed: {
    columns () {
      const staffUser = (this.user.roles && this.user.roles.roles.indexOf(this.userTypeStaff) >= 0)
      const ehr = this.$store.getters.generalSettings.ehr_integration_available
      const columns = [{
        headerName: this.$t('scheduleListTable.time'),
        field: 'visit_date_time',
        colId: 'visit-date-time',
        resizable: false,
        width: 80,
        flex: 0,
        suppressSizeToFit: true,
        cellRenderer: 'DateTimeCellRenderer',
        cellStyle: { textAlign: 'left' },
        valueGetter: params => this.$getJsDateFromTimeString(`${params.data.visit_date} ${params.data.visit_time}`)
      }, {
        headerName: '',
        field: 'avatar',
        colId: 'patient-avatar',
        resizable: false,
        width: 90,
        flex: 0,
        suppressSizeToFit: true,
        cellRenderer: 'AvatarCellRenderer'
      }, {
        headerName: this.$t('scheduleListTable.patient'),
        field: 'name',
        colId: 'patient-name',
        sort: 'asc',
        resizable: false,
        width: 301,
        flex: 0,
        cellRenderer: 'NameCellRenderer',
        cellRendererParams: { homeTab: this.homeTab },
        cellStyle: { textAlign: 'left' },
        valueGetter: params => `${params.data.full_name.toLowerCase()}`
      }, {
        headerName: staffUser && ehr ? this.$t('scheduleListTable.scheduledWith') : this.$t('scheduleListTable.clinicians'),
        field: 'clinicians',
        colId: 'scheduled-with',
        resizable: false,
        cellRenderer: 'ClinicianTeamsCellRenderer',
        cellStyle: { textAlign: 'left' }
      }, {
        headerName: this.$t('scheduleListTable.lastSessionCompleted'),
        field: 'latest_session_data.status_string',
        colId: 'last-completed',
        sort: 'desc',
        sortingOrder: ['desc', 'asc'],
        resizable: false,
        cellRenderer: 'LatestCompletedSessionCellRenderer',
        cellRendererParams: { source: 'patientsList' },
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-center-aligned-header'
      }, {
        headerName: this.$t('scheduleListTable.nextSessionDue'),
        field: 'pending_session.status_string',
        colId: 'next-due',
        resizable: false,
        cellRenderer: 'NextSessionDueCellRenderer',
        cellRendererParams: { source: 'patientsList' },
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-center-aligned-header'
      }, {
        headerName: 'id',
        field: 'access_control_id',
        hide: true
      }, {
        headerName: 'id',
        field: 'mrn',
        hide: true
      }, {
        headerName: 'age',
        field: 'age',
        hide: true
      }, {
        headerName: 'justAdded',
        field: 'justAdded',
        hide: true
      }, {
        headerName: 'teams',
        field: 'teams',
        hide: true
      }, {
        headerName: 'preferred_name',
        field: 'preferred_name',
        hide: true
      }]
      if (!staffUser) {
        columns.splice(3, 1)
      }
      const filteredColumns = ehr ? columns : columns.filter(c => c.field !== 'visit_date_time')
      return filteredColumns
    }
  }
}
