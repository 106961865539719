<template>
  <div class="grid-container">
    <DateSelector
      :date="dateForSelector"
      :due-date-view="dueDateView"
      :end-date="endDate"
      :hide-today="inRange"
      :hide-back="inRange"
      @reset="callAction('reset')"
      @goBackwards="callAction('goBackwards')"
      @goForward="callAction('goForward')"
    />
    <WeekGridHeader
      v-if="gridReady"
      :due-date-view="dueDateView"
    />
    <ClinicianSchedulesListByDay
      v-if="viewMode === 'viewAppointmentsByDay' && !scheduledPatientsByDateLoading"
      ref="appointmentsByDay"
      :appointment-data="scheduledPatientsBySingleDate"
      @openLastSessionDetail="openLastSessionDetail"
      @updateInRangeValue="updateInRangeValue"
      @close="closeLastSessionDetail"
    />
    <PatientsByDueDateListByDay
      v-if="viewMode === 'viewMeasuresByDay' && !scheduledPatientsByDateLoading"
      ref="measuresByDay"
      :measure-data="scheduledPatientsByDate"
      @openLastSessionDetail="openLastSessionDetail"
      @updateInRangeValue="updateInRangeValue"
      @close="closeLastSessionDetail"
    />
    <ClinicianSchedulesListByWeek
      v-if="viewMode === 'viewAppointmentsByWeek' && !scheduledPatientsByDateLoading"
      ref="appointmentsByWeek"
      :appointment-data="scheduledPatientsByDate"
      @openLastSessionDetail="openLastSessionDetail"
      @updateInRangeValue="updateInRangeValue"
      @gridReady="setColumnHeaderProps"
      @close="closeLastSessionDetail"
    />
    <PatientsByDueDateListByWeek
      v-if="viewMode === 'viewMeasuresByWeek' && !scheduledPatientsByDateLoading"
      ref="measuresByWeek"
      :measure-data="scheduledPatientsByDate"
      @openLastSessionDetail="openLastSessionDetail"
      @updateInRangeValue="updateInRangeValue"
      @gridReady="setColumnHeaderProps"
      @close="closeLastSessionDetail"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateSelector from '@/components/common/DateSelector'
import WeekGridHeader from './WeekGridHeader'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { AppointmentsByDate } from '@/mixins/AppointmentsByDate'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import { GridHelpers } from '@/mixins/GridHelpers'
import ClinicianSchedulesListByDay from '@/components/homepage/schedules/ClinicianSchedulesListByDay'
import PatientsByDueDateListByDay from '@/components/homepage/patientsByDueDate/PatientsByDueDateListByDay'
import ClinicianSchedulesListByWeek from '@/components/homepage/schedules/ClinicianSchedulesListByWeek'
import PatientsByDueDateListByWeek from '@/components/homepage/patientsByDueDate/PatientsByDueDateListByWeek'

export default {
  name: 'PatientsByDayOrWeek',
  components: {
    DateSelector,
    WeekGridHeader,
    ClinicianSchedulesListByDay,
    PatientsByDueDateListByDay,
    ClinicianSchedulesListByWeek,
    PatientsByDueDateListByWeek
  },
  mixins: [DateTimeHelper, AppointmentsByDate, LastSessionDetail, GridHelpers],
  props: {
    dueDateView: {
      type: Boolean,
      default: false
    },
    showTodayAndThisWeek: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hideToday: false,
      hideBack: false,
      inRange: true,
      gridReady: false,
      tabChanged: false
    }
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUiSettings',
      uiSettingsLoaded: 'getUiSettingsLoaded',
      homeTab: 'getHomeTab',
      scheduledPatientsBySingleDate: 'getScheduledPatientsBySingleDate',
      scheduledPatientsByDate: 'getScheduledPatientsByDate',
      scheduledPatientsByDateLoading: 'getScheduledPatientsByDateLoading'
    }),
    dateRangeInput () {
      return this.isInRange ? '' : this.date
    },
    dateForSelector () {
      return this.homeTab === 'week' ? this.isInRange ? this.getFullWeek()[0] : moment(this.date).startOf('isoWeek') : this.date
    },
    endDate () {
      return this.homeTab === 'week' ? this.isInRange ? this.getLastDayInRange() : moment(this.date).endOf('isoWeek') : ''
    },
    appointments () {
      return this.scheduledPatientsByDate || this.scheduledPatientsBySingleDate
    },
    currentHomeTabValue () {
      return this.homeTab
    },
    viewMode () {
      if (this.homeTab === 'today' && !this.dueDateView) {
        return 'viewAppointmentsByDay'
      }
      if (this.homeTab === 'today' && this.appointmentView && this.dueDateView) {
        return 'viewMeasuresByDay'
      }
      if (this.homeTab === 'week' && this.appointmentView && !this.dueDateView) {
        return 'viewAppointmentsByWeek'
      }
      if (this.homeTab === 'week' && this.appointmentView && this.dueDateView) {
        return 'viewMeasuresByWeek'
      }
      return null
    }
  },
  watch: {
    currentHomeTabValue: {
      immediate: true,
      handler (newVal, oldVal) {
        // need to know if user switched between day and week so we can fetch new data
        this.tabChanged = newVal !== oldVal
      }
    }
  },
  created () {
    document.body.classList.add('homepage')
    if (Object.keys(this.appointments).length === 0 || this.tabChanged) {
      this.fetchData(this.date)
      this.tabChanged = false
    }
    this.callAction()
  },
  beforeDestroy () {
    document.body.classList.remove('homepage')
  },
  methods: {
    refreshList () {
      this.fetchData(this.date)
    },
    callAction (action) {
      this.$nextTick().then(() => {
        try {
          if (action && typeof this[action] === 'function') {
            this[action]()
          }
          this.inRange = this.isInRange
        } catch (error) {
          console.log(error) // eslint-disable-line no-console
        }
      })
    },
    updateInRangeValue (value) {
      this.inRange = value
    },
    getLastDayInRange () {
      const range = this.getFullWeek()
      const length = range.length
      return range[length - 1]
    },
    setColumnHeaderProps () {
      this.gridReady = true
    },
    resetGrid () {
      const component = this.getChildRef()
      if (component) {
        component.resetGrid()
      }
    },
    getChildRef () {
      let component = null
      switch (this.viewMode) {
        case 'viewAppointmentsByDay':
          component = this.$refs.appointmentsByDay
          break
        case 'viewMeasuresByDay':
          component = this.$refs.measuresByDay
          break
        case 'viewAppointmentsByWeek':
          component = this.$refs.appointmentsByWeek
          break
        case 'viewMeasuresByWeek':
          component = this.$refs.measuresByWeek
          break
      }
      return component
    }
  }
}
</script>
<style lang="scss">
  .homepage {
    overflow: hidden;
  }
  .grid-container {
    height: 100%;
  }
  .ag-theme-owl .ag-row .ag-cell .ag-cell-list-item .ag-cell-click {
    &.center-vertical {
      height: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      font-weight: 700;
    }
  }
</style>
