<!-- TODO: cleanup week tab grid header -->
<template>
  <div
    v-if="homeTab === 'week'"
    class="week-grid-header-container"
  >
    <div class="week-grid-header">
      <div
        v-if="ehrOn"
        class="week-grid-header-cell header-cell-time"
        :style="`left: ${columnProperties['visit-date-time'].left};`"
      >
        <div class="week-grid-header-label-cell">
          <span>Time</span>
        </div>
      </div>
      <div
        class="week-grid-header-cell header-cell-avatar"
        :style="`left: ${columnProperties['patient-avatar'].left};`"
      >
        <div class="week-grid-header-label-cell" />
      </div>
      <div
        class="week-grid-header-cell header-cell-patient"
        :style="`width: ${columnProperties['patient-name'].width}; left: ${columnProperties['patient-name'].left}; text-align: left`"
      >
        <div class="week-grid-header-label-cell">
          <span>Patient</span>
        </div>
      </div>
      <div
        v-if="!ehrOn && !isClinician"
        class="week-grid-header-cell header-cell-clinician"
        :style="`width: ${columnProperties['scheduled-with'].width}; left: ${columnProperties['scheduled-with'].left}; justify-content: left`"
      >
        <div class="week-grid-header-label-cell">
          <span>Clinician(s)</span>
        </div>
      </div>
      <div
        v-else-if="ehrOn && !isClinician"
        class="week-grid-header-cell header-cell-clinician"
        :style="`width: ${columnProperties['scheduled-with'].width}; left: ${columnProperties['scheduled-with'].left}; justify-content: left`"
      >
        <div class="week-grid-header-label-cell">
          <span>Scheduled With</span>
        </div>
      </div>
      <div
        class="week-grid-header-cell header-cell-last-completed"
        :style="`width: ${columnProperties['last-completed'].width}; left: ${columnProperties['last-completed'].left}; justify-content: center`"
      >
        <div class="week-grid-header-label-cell">
          <span>Last Completed</span>
        </div>
      </div>
      <div
        class="week-grid-header-cell header-cell-next-due"
        :style="`width: ${columnProperties['next-due'].width}; left: ${columnProperties['next-due'].left}; justify-content: center`"
      >
        <div class="week-grid-header-label-cell">
          <span>Next Due</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WeekGridHeader',
  props: ['dueDateView'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      columnProperties: {
        'visit-date-time': {
          width: '0',
          left: '0'
        },
        'patient-avatar': {
          width: '0',
          left: '0'
        },
        'patient-name': {
          width: '0',
          left: '0'
        },
        'scheduled-with': {
          width: '0',
          left: '0'
        },
        'last-completed': {
          width: '0',
          left: '0'
        },
        'next-due': {
          width: '0',
          left: '0'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser',
      homeTab: 'getHomeTab'
    }),
    isClinician () {
      return this.user.roles && (this.user.roles.roles.indexOf('clinician') >= 0) && this.user.roles.roles.length === 1
    },
    ehrOn () {
      return !this.dueDateView
    }
  },
  mounted () {
    // set column header widths and positions based on grid IDs
    for (const item in this.columnProperties) {
      const selector = document.querySelector(`[col-id=${item}]`) ? document.querySelector(`[col-id=${item}]`) : null
      if (selector) {
        this.columnProperties[item].width = selector.style.width
        this.columnProperties[item].left = selector.style.left
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  // week tab grid header
  .week-grid-header-container {
    height: 51px;
    min-height: 51px;
    width: 865px;
    padding-top: 20px;
    position: relative;
    background-color: #fff;
    flex-direction: column;
    border-bottom: 3px solid #f1f5f8;
    .week-grid-header {
      top: 0px;
      height: 50px;
      width: 865px;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      color: #0a3560;
      .week-grid-header-cell {
        width: 100%;
        flex-direction: row;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: normal;
        display: inline-flex;
        align-items: center;
        position: absolute;
        overflow: hidden;

        &.header-cell-clinician {
          min-width: 167px;
        }
        &.header-cell-last-completed {
          min-width: 167px;
        }
        &.header-cell-next-due {
          min-width: 167px;
        }
      }
      .week-grid-header-label-cell {
        display: flex;
        align-items: flex-start;
        align-items: center;
        padding-left: 0.75em;
        padding-right: 0.75em;
        padding-bottom: 10px;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: normal;
        span {
          word-break: keep-all;
          word-wrap: break-word;
          white-space: normal;
          text-overflow: normal;
          justify-content: center;
          align-self: flex-end;
        }
      }
    }
  }
</style>
