<template>
  <div
    class="clinician-schedules-list by-day"
    :class="{'ehr-disabled': dueDateView}"
  >
    <PatientsByDueDateListSingleDay
      :id="getDateId(date)"
      ref="singleDayMeasures"
      :date="date"
      :measures="measures"
      :show-selector="true"
      :hide-back="isInRange"
      :hide-today="isInRange"
      @reset="reset"
      @goBackwards="goBackwards"
      @goForward="goForward"
      @openLastSessionDetail="patient => this.$emit('openLastSessionDetail', patient)"
      @close="closeLastSessionDetail"
    />
  </div>
</template>

<script>
import PatientsByDueDateListSingleDay from '@/components/homepage/patientsByDueDate/PatientsByDueDateListSingleDay'
import { AppointmentsByDate } from '@/mixins/AppointmentsByDate'

export default {
  name: 'PatientsByDueDateListByDay',
  components: {
    PatientsByDueDateListSingleDay
  },
  mixins: [AppointmentsByDate],
  props: {
    measureData: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      dueDateView: true
    }
  },
  computed: {
    firstElement () {
      return Object.keys(this.measureData.days)[0]
    },
    measures () {
      return this.measureData.days[this.firstElement]
    }
  },
  methods: {
    resetGrid () {
      this.$refs.singleDayMeasures.resetGrid()
    },
    closeLastSessionDetail (params) {
      this.$emit('close', params)
    }
  }
}
</script>
<style lang="scss">
.body-clinician-layout {
  .clinician-schedules-list {
    &.ehr-disabled {
      background-color: #fff;
    }
    &.by-day{
      .alert {
        margin-bottom: 0;
      }
      .clinician-schedules-list-table {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
