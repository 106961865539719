<template>
  <div
    :id="divId"
    class="clinician-details-wrapper"
  >
    <div v-if="clinicians && clinicians.length">
      <span
        v-for="(clinician, index) in clinicians"
        :key="'clinician'+index"
        class="list-clinician"
      >{{ clinician }}
      </span>
    </div>
    <div v-if="teams && teams.length">
      <span
        v-for="(team, index) in teams"
        :key="'team'+index"
        class="list-clinician"
      >
        {{ team }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClinicianDetailsTooltip',
  props: {
    clinicians: {
      default: null,
      type: Array
    },
    row: {
      default: null,
      type: String
    },
    teams: {
      default: null,
      type: Array
    },
    gridId: {
      default: null,
      type: String
    }
  },
  computed: {
    divId () {
      return this.gridId ? `ag-tooltip-${this.gridId}-clinicians-${this.row}` : `ag-tooltip-clinicians-${this.row}`
    }
  }
}
</script>
