<template>
  <div
    class="ag-cell-list-item"
  >
    <a
      class="ag-cell-click"
      @click="showDetail"
    >
      <initials-circle
        first-id="responsive-outer-circle"
        second-id="responsive-inner-circle"
        class="gray-border-circle initials-circle"
        :first="firstInitial"
        :second="secondInitial"
        :alert="params.data.active_alert_count"
        :inactive="isInactive"
      />
    </a>
  </div>
</template>

<script>
import InitialsCircle from '@/components/common/InitialsCircle'
import { STATUS_INACTIVE } from '@/data/userStatusValues'

export default {
  components: {
    InitialsCircle
  },
  computed: {
    isInactive () {
      return this.params.data.status === STATUS_INACTIVE
    }
  },
  beforeMount () {
    this.firstInitial = this.params.data.first_name.substring(0, 1)
    this.secondInitial = this.params.data.last_name.substring(0, 1)
  },
  methods: {
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
<style lang="scss">
#responsive-outer-circle {
  margin: 6px 10px;
}
</style>
