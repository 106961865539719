<template>
  <div
    class="clinician-schedules-list-table"
    :class="{'weekly-table': homeTab === 'week'}"
  >
    <DateTitle
      v-if="homeTab === 'week'"
      :date="date"
    />
    <ag-grid-vue
      v-if="hasAppointments && homeTab === 'week'"
      :ref="$attrs.id"
      style="height: 90%"
      class="ag-theme-owl"
      :column-defs="columns"
      :context="context"
      :row-data="appointments"
      row-selection="multiple"
      animate-rows="true"
      dom-layout="autoHeight"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :overlay-no-rows-template="noRowsOverlay"
      @grid-ready="onGridReady"
      @column-resized="onGridColumnsChanged"
      @model-updated="onModelUpdated"
      @row-selected="onRowSelected"
    />
    <ag-grid-vue
      v-else
      :ref="$attrs.id"
      :style="gridHeight"
      class="ag-theme-owl"
      :column-defs="columns"
      :context="context"
      :row-data="appointments"
      row-selection="multiple"
      animate-rows="true"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :overlay-no-rows-template="noRowsOverlay"
      @grid-ready="onGridReady"
      @column-resized="onGridColumnsChanged"
      @bodyScroll="handleScrollEvent"
      @model-updated="onModelUpdated"
    />
    <div
      class="ag-tooltip-container"
      :class="{ visible: isTooltipVisible }"
      :style="tooltipPosition"
    />
    <div
      v-if="searchString.length > 1 && !validSearchResult && homeTab === 'today' && hasAppointments"
      class="no-patients-link"
    >
      {{ $t('noPatientsFound') }}
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/assets/ag-grid/ag-theme-owl.css'
import { PatientsByDueDate } from '@/components/homepage/columnDefs/PatientsByDueDateColumnsHelper'
import DateTitle from '@/components/common/DateTitle'
import { mapGetters } from 'vuex'
import { USER_TYPE_STAFF } from '@/data/permissions'
import { GridHelpers } from '@/mixins/GridHelpers'

export default {
  name: 'ClinicianSchedulesListSingleDay',
  components: {
    AgGridVue,
    DateTitle
  },
  mixins: [PatientsByDueDate, GridHelpers],
  props: ['date', 'appointments', 'dayIndex'],
  data () {
    return {
      userTypeStaff: USER_TYPE_STAFF,
      rowActive: null,
      loadingOverlay: null,
      noRowsOverlay: null,
      isTooltipVisible: false,
      tooltipPosition: 0,
      tooltipCell: {},
      validSearchResult: false,
      defaultSortModel: [
        {
          colId: 'visit-date-time',
          sort: 'asc',
          sortIndex: 0
        },
        {
          colId: 'patient-name',
          sort: 'asc',
          sortIndex: 1
        }
      ],
      rowHeaderData: {
        obj: {
          placeholder: []
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      detailViewOpened: 'getDetailVewOpened',
      user: 'loggedInUser',
      homeTab: 'getHomeTab',
      searchString: 'getSearchString'
    }),
    gridOptions () {
      return {
        columnDefs: this.columnDefs,
        rowData: this.rowData,
        defaultColDef: this.defaultColDef,
        headerHeight: this.headerHeight,
        suppressHorizontalScroll: true,
        rowHeight: 85
      }
    },
    defaultColDef () {
      return {
        flex: 1,
        resizable: false,
        autoHeight: true,
        sortable: false,
        suppressMovable: true
      }
    },
    hasAppointments () {
      return this.appointments.length > 0
    },
    headerHeight () {
      // needs cleanup, grid header was replicated to allow for it to be pinned.
      return this.homeTab === 'week' ? this.dayIndex > 0 ? 0 : 0 : ''
    },
    gridHeight () {
      return this.homeTab === 'today' ? 'height: 92%' : this.dayIndex === 0 ? `height: ${this.headerHeight + 50}px` : 'height: 50px'
    }
  },
  watch: {
    searchString (newVal) {
      if (this.hasAppointments) {
        if (this.gridApi) {
          this.gridApi.hideOverlay()
        }
        this.validSearchResult = false
        this.externalFilterChanged(newVal)
        if (this.homeTab === 'week' && this.searchString.length > 1 && !this.validSearchResult && this.gridApi) {
          this.gridApi.showNoRowsOverlay()
        }
      }
    }
  },
  beforeMount () {
    this.context = { homepageGrid: this }
  },
  created () {
    this.loadingOverlay = `<div><span>${this.$t('loading')}</span><i class="fas fa-circle-notch fa-spin" /></div>`
    this.noRowsOverlay = `<div><span>${this.$t('scheduleListTable.noPatientsFound')}</span></div>`
  },
  methods: {
    openDetail (params) {
      this.$emit('openLastSessionDetail', params)
      this.hideTooltip()
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.gridApi.sizeColumnsToFit()
      this.columnApi.applyColumnState({ state: this.defaultSortModel })
      if (this.isExternalFilterPresent) {
        this.gridApi.onFilterChanged()
      }
      if (this.homeTab === 'week') {
        this.scrollToPosition()
      } else {
        this.scrollToRow()
      }
      if (this.homeTab === 'week' && this.searchString.length > 1 && !this.validSearchResult) {
        this.gridApi.showNoRowsOverlay()
      }
    },
    onGridColumnsChanged () {
      this.$emit('gridReady')
    },
    onModelUpdated (params) {
      params.api.deselectAll()
      this.$emit('close', params)
    },
    onRowSelected (params) {
      if (params.node.isSelected() && this.homeTab === 'week') {
        this.$emit('resetGrid', this.dayIndex)
      }
    },
    resetGrid () {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    },
    headerHeightGetter () {
      const columnHeaderTexts = [
        ...document.querySelectorAll('.ag-header-cell-text')
      ]
      const clientHeights = columnHeaderTexts.map(
        headerText => headerText.clientHeight
      )
      const tallestHeaderTextHeight = Math.max(...clientHeights)

      return tallestHeaderTextHeight
    },
    headerHeightSetter () {
      const padding = 10
      const height = this.headerHeightGetter() + padding
      this.gridOptions.api.setHeaderHeight(height)
    }
  }
}
</script>
<style lang="scss">
.clinician-schedules-list-table {
  height: 100%;

  &.weekly-table {
    margin-top: 0 !important;
  }

  .no-patients-link {
    -webkit-font-smoothing: antialiased;
    width: 865px;
    font-weight: 100;
    font-size: 15px;
    position: absolute;
    top: 573.5px;
    z-index: 10;
  }
}
</style>
