<template>
  <div
    v-if="$route.name !== 'ClinicianPatientsList'"
    class="my-programs-nav"
  >
    <ul class="nav nav-tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="nav-item"
      >
        <HomeTabItemNew
          :tab="tab"
          @hideAll="hideAll"
        />
      </li>
    </ul>
    <div class="search">
      <filter-selector
        v-if="showPatientSelector && homeTab !== 'programs'"
        filter-element="Patients"
        :hide-status-selector="hideStatusSelector"
        @filtersChanged="emitFilterChanged"
      />
      <search-input
        v-model="search"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import SearchInput from '@/components/common/SearchInput'
import HomeTabItemNew from '@/components/common/HomeTabItemNew'
import FilterSelector from '@/components/common/FilterSelector'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'

export default {
  name: 'ClinicianHomeTabs',
  components: {
    HomeTabItemNew,
    SearchInput,
    FilterSelector
  },
  mixins: [UserManagementComponentMixin, Helpers],
  props: ['placeholder', 'showPatientSelector', 'hideStatusSelector'],
  data () {
    return {
      selected: {},
      tabs: [],
      programKey: 0
    }
  },
  computed: {
    ...mapGetters({
      homeTab: 'getHomeTab',
      settings: 'generalSettings',
      searchString: 'getSearchString',
      programs: 'getProgramsByRole',
      programsLoading: 'getProgramsByRoleLoading',
      selectedProgram: 'getSelectedProgram'
    }),
    dueDateView () {
      return this.appointmentView && !this.settings.ehr_integration_available
    },
    showTodayAndThisWeek () {
      const settingAllows = this.appointmentView || this.dueDateView
      const roleAllows = this.userHasAccessToTodayAndWeekTabs()
      return roleAllows && settingAllows
    },
    programIsSelected () {
      return Object.keys(this.selectedProgram).length
    },
    search: {
      get () {
        return this.searchString
      },
      set (value) {
        this.$emit('searching', value)
      }
    }
  },
  watch: {
    homeTab (n, o) {
      this.search = ''
    }
  },
  created () {
    const tabs = []
    this.addTodayAndThisWeekTabs(tabs)
    this.addProgramTabs(tabs)
    this.addNewResultsTabs(tabs)
    try {
      const selectedTab = this.homeTab ? this.homeTab : this.$route.query.homeTab ? this.$route.query.homeTab : tabs[0].items.find(i => i.selected).name
      this.SET_HOME_TABS(selectedTab)
    } catch (e) {}
    this.tabs = tabs
  },
  methods: {
    ...mapMutations({
      SET_HOME_TABS: 'user/SET_HOME_TABS',
      setSelectedProgram: 'programs/SET_SELECTED_PROGRAM',
      setDetailViewOpened: 'user/SET_DETAIL_VIEW_OPENED'
    }),
    hideAll () {
      this.tabs.forEach(tab => {
        tab.opened = false
      })
    },
    addNewResultsTabs (tabs) {
      if (this.userHasAccessToNewResults()) {
        tabs.push({
          opened: false,
          items: [{
            label: 'New Results',
            name: 'newResults',
            link: 'ClinicianPatientsNewResults',
            query: { homeTab: 'new-results' },
            selected: true
          }]
        })
      }
    },
    addTodayAndThisWeekTabs (tabs) {
      if (!this.showTodayAndThisWeek) {
        return false
      }

      tabs.push({
        opened: false,
        items: [{
          label: 'Today',
          name: 'today',
          link: 'PatientsByDay',
          query: { homeTab: 'today' },
          selected: !this.homeTab || this.homeTab === 'today' || (this.homeTab !== 'today' && this.homeTab !== 'week')
        }, {
          label: 'Week',
          name: 'week',
          link: 'PatientsByWeek',
          query: { homeTab: 'week' },
          selected: this.homeTab === 'week'
        }]
      })
      return tabs
    },
    addProgramTabs (tabs) {
      if (!this.programsView) {
        return false
      }
      // TODO: do we need to check access to this tab?
      tabs.push({
        opened: false,
        items: [{
          label: 'Programs',
          name: 'programs',
          link: 'PatientsByProgram',
          query: { homeTab: 'programs' },
          selected: true
        }]
      })
    },
    emitFilterChanged () {
      this.$emit('close')
      this.$emit('filtersChanged')
    }
  }
}
</script>
