<template>
  <div
    class="ag-cell-list-item"
  >
    <a
      class="ag-cell-click cell-centered"
      @click="showDetail"
    >
      <span
        v-if="nextVisit"
      >{{ visitDate }}
      </span>
    </a>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'NextVisitCellRenderer',
  mixins: [DateTimeHelper],
  data () {
    return {
      data: null,
      source: ''
    }
  },
  computed: {
    visitDate () {
      return this.nextVisit ? this.$toLocal(this.nextVisit, 'MMM DD, YYYY') : ''
    }
  },
  beforeMount () {
    this.nextVisit = this.params.value
  },
  methods: {
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
