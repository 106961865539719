<template>
  <div class="ag-cell-list-item clinician-patients-list">
    <a
      class="ag-cell-click"
      @click="showDetail"
    >
      {{ dayCount }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'DaysCellRenderer',
  data () {
    return {
      dayCount: ''
    }
  },
  beforeMount () {
    this.dayCount = this.params.data.days_in_program
  },
  methods: {
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
