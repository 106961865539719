<template>
  <div
    class="ag-cell-list-item"
  >
    <a
      class="ag-cell-click cell-centered"
      @click="showDetail"
    >
      <span
        v-if="showContent && sessionDate"
        class="next-session-due-item"
        :class="{'one-line': oneLine}"
      >
        <span> {{ sessionDate }} </span>
        <br v-if="!oneLine">
        <SessionIconButton :status="parseInt(status)" />
        <span
          v-if="!oneLine"
          class="status-text"
        >
          {{ lastSessionStatusString }}
        </span>
      </span>
      <span v-else>
        {{ $t('none') }}
      </span>
    </a>
  </div>
</template>

<script>
import { SESSION_STATUS_COMPLETED, SESSION_STATUS_NO_SESSIONS } from '@/data/lastSessionStatus'
import lastSesionStatusText from '@/data/lastSesionStatusText'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import SessionIconButton from '@/components/common/SessionIconButton'

export default {
  name: 'NextSessionDueCellRenderer',
  components: {
    SessionIconButton
  },
  mixins: [DateTimeHelper],
  data () {
    return {
      data: null,
      source: '',
      oneLine: false
    }
  },
  computed: {
    sessionDate () {
      let dateString = ''
      const statusIsValid = this.status !== this.SESSION_STATUS_NO_SESSIONS && this.status !== SESSION_STATUS_COMPLETED
      switch (this.source) {
        case 'scheduleListTable':
          dateString = this.data.next_session_data && this.data.next_session_data.date ? this.data.next_session_data.date : null
          break
        case 'patientDetail':
          dateString = this.data.date
          break
        case 'scheduleListTableByDudeDate':
        case 'patientsList':
        case 'patientsByProgram':
          dateString = statusIsValid ? this.data.next_session_date : ''
          break
      }
      return dateString ? this.$toLocal(dateString, 'MMM DD, YYYY') : ''
    },
    status () {
      let status = null
      switch (this.source) {
        case 'scheduleListTable':
          status = this.data.next_session_data ? this.data.next_session_data.status : null
          break
        case 'patientDetail':
          status = this.data.status
          break
        case 'scheduleListTableByDudeDate':
        case 'patientsList':
        case 'patientsByProgram':
          status = this.data.next_session_status
          break
        default:
          status = null
          break
      }
      return this.getCastedStatus(status)
    },
    showContent () {
      return this.status !== null && this.status !== SESSION_STATUS_NO_SESSIONS
    },
    lastSessionStatusString () {
      if (this.status === null) {
        return ''
      }
      return lastSesionStatusText[this.status]
    }
  },
  beforeMount () {
    this.data = this.params.data
    this.source = this.params.source
  },
  methods: {
    getCastedStatus (value) {
      return Number.isInteger(value) ? parseInt(value) : null
    },
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
<style lang="scss" scoped>
.next-session-due-item {
  &.one-line {
    display: flex;
    flex-direction: row;
    i {
      margin-left: 5px;
    }
  }
}
</style>
