<template>
  <div
    class="btn-group"
    :class="{show: tab.opened}"
  >
    <router-link
      class="nav-link keep-opened"
      :class="{active: tabIsActive(tab), multiple: tab.items.length > 1}"
      :to="{query: tab.items[0].query}"
      @mouseout="mouseout"
    >
      <span class="keep-opened">{{ getSelectedLabel(tab) }}</span>
    </router-link>
    <i
      v-if="tab.items.length > 1"
      class="fal home-toggle keep-opened"
      :class="{'fa-angle-down': !tab.opened, 'fa-angle-up': tab.opened}"
      @mouseout="mouseout"
      @click="tab.opened = !tab.opened"
    />
    <div
      v-if="tab.items.length > 1"
      class="dropdown-menu keep-opened"
      :class="{show: tab.opened}"
      @mouseout="mouseout"
    >
      <router-link
        v-for="(tabItem, i) in tab.items"
        :key="i"
        class="dropdown-item keep-opened"
        :class="{selected: tabItem.selected}"
        :to="{query: tabItem.query}"
      >
        {{ tabItem.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'HomeTabItemNew',
  props: ['tab'], // eslint-disable-line vue/require-prop-types
  watch: {
    // updated selected day/week tab on route change, since we can't use @click in the router-link
    '$route.query.homeTab': {
      handler (newVal) {
        this.setSelectedTab()
        this.changeTab(newVal)
      }
    }
  },
  created () {
    this.setSelectedTab()
  },
  methods: {
    ...mapMutations({
      SET_HOME_TABS: 'user/SET_HOME_TABS',
      setDetailViewOpened: 'user/SET_DETAIL_VIEW_OPENED'
    }),
    hideAll () {
      this.$emit('hideAll')
    },
    changeTab (tab) {
      this.setDetailViewOpened(false)
      this.SET_HOME_TABS(tab)
    },
    getSelectedLabel (tab) {
      const item = tab.items.find(item => item.selected)
      return item ? item.label : tab.items[0].label
    },
    mouseout (tab) {
      if (!tab.relatedTarget || !tab.relatedTarget.classList) {
        this.hideAll()
      } else {
        const value = tab.relatedTarget.classList.value ? tab.relatedTarget.classList.value : tab.relatedTarget.classList
        if (!`${value}`.includes('keep-opened')) {
          this.hideAll()
        }
      }
    },
    tabIsActive (tab) {
      const hasActiveItem = tab.items.find(i => i.query.homeTab === this.$route.query.homeTab)
      return !!hasActiveItem
    },
    setSelectedTab () {
      this.tab.items.forEach(i => {
        if (i.query.homeTab === this.$route.query.homeTab) {
          i.selected = true
        } else {
          i.selected = false
        }
      })
    }
  }
}
</script>
