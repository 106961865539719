import AvatarCellRenderer from '@/components/homepage/cellRenderers/AvatarCellRenderer.vue'
import NameCellRenderer from '@/components/homepage/cellRenderers/NameCellRenderer.vue'
import DaysCellRenderer from '@/components/homepage/cellRenderers/DaysCellRenderer.vue'
import EpisodeCellRenderer from '@/components/homepage/cellRenderers/EpisodeCellRenderer.vue'
import NextSessionDueCellRenderer from '@/components/homepage/cellRenderers/NextSessionDueCellRenderer.vue'
import LatestCompletedSessionCellRenderer from '@/components/homepage/cellRenderers/LatestCompletedSessionCellRenderer.vue'
import ActionsCellRenderer from '@/components/homepage/cellRenderers/ActionsCellRenderer.vue'

window.dateComparator = function dateComparator (date1, date2) {
  const date1Number = this.monthToComparableNumber(date1)
  const date2Number = this.monthToComparableNumber(date2)
  if (date1Number === null && date2Number === null) {
    return 0
  }
  if (date1Number === null) {
    return -0
  }
  if (date2Number === null) {
    return 1
  }
  return date1Number - date2Number
}

window.monthToComparableNumber = function monthToComparableNumber (date) { // eg 29/08/2004 gets converted to 20040829
  if (date === undefined || date === null || date.length !== 10) {
    return null
  }
  const yearNumber = Number.parseInt(date.substring(6, 10))
  const monthNumber = Number.parseInt(date.substring(3, 5))
  const dayNumber = Number.parseInt(date.substring(0, 2))
  return yearNumber * 10000 + monthNumber * 100 + dayNumber
}

export const ProgramList = {
  components: {
    AvatarCellRenderer,
    NameCellRenderer,
    DaysCellRenderer,
    EpisodeCellRenderer,
    NextSessionDueCellRenderer,
    LatestCompletedSessionCellRenderer,
    ActionsCellRenderer
  },
  computed: {
    columns () {
      const columns = [{
        headerName: '',
        field: 'avatar',
        colId: 'patient-avatar',
        resizable: false,
        width: 80,
        suppressSizeToFit: true,
        flex: 0,
        cellRenderer: 'AvatarCellRenderer'
      }, {
        headerName: this.$t('patientsTable.patient'),
        field: 'name',
        colId: 'patient-name',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        width: this.selectedProgram.uuid === null || this.selectedProgram.uuid === 'null' ? 374 : 274,
        suppressSizeToFit: true,
        flex: 0,
        cellRenderer: 'NameCellRenderer',
        cellRendererParams: { homeTab: this.homeTab },
        cellStyle: { textAlign: 'left' },
        valueGetter: params => `${params.data.full_name.toLowerCase()}`
      }, {
        headerName: this.$t('patientsByProgramTable.daysIn'),
        field: 'days_in_program',
        colId: 'days-in-program',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        width: 230,
        cellRenderer: 'DaysCellRenderer',
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' }
      }, {
        headerName: this.$t('patientsTable.lastSessionCompleted'),
        field: 'latest_completed_session_date',
        colId: 'latest-completed-session-date',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        cellRenderer: 'LatestCompletedSessionCellRenderer',
        cellRendererParams: { source: 'patientsByProgram' },
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' },
        comparator: this.dateComparator,
        valueGetter: params => new Date(params.data.latest_completed_session_completed_date)
      }, {
        headerName: this.$t('patientsTable.nextSessionDue'),
        field: 'latest_session_date',
        colId: 'latest-session-date',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        cellRenderer: 'NextSessionDueCellRenderer',
        cellRendererParams: { source: 'patientsByProgram' },
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' },
        comparator: this.dateComparator,
        valueGetter: params => new Date(params.data.next_session_date)
      }, {
        headerName: '',
        field: 'actions',
        colId: 'actions',
        width: 36,
        flex: 0,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        cellRenderer: 'ActionsCellRenderer',
        cellRendererParams: {
          rowData: this.params,
          showActions: this.selectedProgram.uuid !== null && this.selectedProgram.uuid !== 'null'
        },
        headerClass: 'ag-center-aligned-header'
      }, {
        headerName: 'id',
        field: 'access_control_id',
        hide: true
      }, {
        headerName: 'id',
        field: 'mrn',
        hide: true
      }, {
        headerName: 'age',
        field: 'age',
        hide: true
      }, {
        headerName: 'justAdded',
        field: 'justAdded',
        hide: true
      }, {
        headerName: 'teams',
        field: 'teams',
        hide: true
      }, {
        headerName: 'preferred_name',
        field: 'preferred_name',
        hide: true
      }]
      const filteredColumns = this.selectedProgram.uuid === null || this.selectedProgram.uuid === 'null' ? columns.filter(c => c.field !== 'days_in_program') : columns
      return filteredColumns
    }
  }
}
