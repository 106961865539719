<template>
  <div class="date-selector">
    <div class="selector">
      <span
        class="pointer arrow"
        @click="$emit('goBackwards')"
      ><i class="fal fa-angle-left" /></span>
      <span
        v-if="endDate"
        class="date-range"
      >
        <date-title :date="date" /> - <date-title :date="endDate" />
      </span>
      <date-title
        v-else
        :date="date"
      />
      <span
        class="pointer arrow"
        @click="$emit('goForward')"
      ><i class="fal fa-angle-right" /></span>
      <owl-button
        v-if="!hideToday"
        class="pointer today"
        variant="secondary"
        label="Today"
        @on-click="resetToday"
      />
    </div>
  </div>
</template>

<script>
import { OwlButton } from 'owl-components'
import DateTitle from '@/components/common/DateTitle'
import { mapGetters } from 'vuex'
export default {
  name: 'DateSelector',
  components: {
    DateTitle,
    OwlButton
  },
  props: ['date', 'dueDateView', 'endDate', 'hideToday', 'hideBack'], // eslint-disable-line vue/require-prop-types
  computed: {
    ...mapGetters({
      detailViewOpened: 'getDetailVewOpened',
      homeTab: 'getHomeTab'
    })
  },
  methods: {
    resetToday () {
      this.$emit('reset')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './../../assets/styles/override.scss';
  .date-selector {
    text-align: left;
    background: $white;
    color: $navy-blue;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    .selector {
      font-size: 14px;
      display: flex;
      padding: 5px 0 10px 15px;
      .arrow {
        font-size: 28px;
        margin-top: 5px;
      }
      .today {
        height: 30px;
        text-transform: uppercase;
        margin: 6px 0 0 10px;
      }
    }
    .date-title {
      padding: 2px 10px 0 10px;
    }
    .date-range {
      padding-top: 7px;
      .date-title {
      display: inline-block;
      }
    }
  }
</style>
