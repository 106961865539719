<template>
  <div
    v-if="clinicianCount > 1"
    :id="cellId"
    class="ag-cell-list-item clinician-list"
  >
    <a
      class="ag-cell-click"
      @click.self="showDetail"
    >
      <button
        @click.self="showTooltip"
      >
        {{ clinicianCount }} clinicians
      </button>
      <ClinicianDetailsTooltip
        :teams="teams"
        :clinicians="clinicians"
        :row="params.node.id"
        :grid-id="gridId"
      />
    </a>
  </div>
  <div
    v-else
    class="ag-cell-list-item clinician-list"
  >
    <a
      class="ag-cell-click"
      @click="showDetail"
    >
      <span
        v-for="(clinician, index) in clinicians"
        :key="'clinician'+index"
      >{{ textTruncate(clinician) }}<br>
      </span>
      <div v-if="teams && teams.length">
        <span
          v-for="(team, index) in teams"
          :key="'teams'+index"
        >
          {{ team }}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
import ClinicianDetailsTooltip from './ClinicianDetailsTooltip.vue'

export default {
  components: {
    ClinicianDetailsTooltip
  },
  data () {
    return {
      clinicians: [],
      teams: []
    }
  },
  computed: {
    clinicianCount () {
      return this.clinicians.length + this.teams.length
    },
    gridId () {
      return this.params.context.homepageGrid.$attrs ? this.params.context.homepageGrid.$attrs.id : null
    },
    cellId () {
      return `data-cell-${this.gridId}-clinicians-${this.params.node.id}`
    }
  },
  beforeMount () {
    this.clinicians = this.params.data.clinicians ? this.params.data.clinicians : []
    this.teams = this.params.data.teams ? this.params.data.teams : this.params.data.clinician_teams ? this.params.data.clinician_teams : []
  },
  methods: {
    showTooltip () {
      const dataObj = {
        gridId: this.gridId,
        colId: 'clinicians',
        nodeId: this.params.node.id
      }

      // invoke method from parent
      this.params.context.homepageGrid.openTooltip(dataObj)
    },
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    },
    textTruncate (name, length, end) {
      if (length == null) {
        length = 30
      }
      if (end == null) {
        end = '...'
      }
      if (name.length > length) {
        return name.substring(0, length - end.length) + end
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/override.scss';
  .clinician-list {
    @apply tw-pt-5 tw-leading-tight;
    span {
      @apply tw-inline-block;
      padding-top: 5px;
      white-space: normal;
      word-break: break-all;
    }
    button {
      @apply tw-font-thin hover:tw-underline tw-outline-none;
      padding-top: 5px;
    }
  }
  .clinician-details-wrapper {
    @apply tw-hidden;
  }
</style>
