<template>
  <div
    :id="rowId"
    class="ag-cell-list-item clinician-schedules-list actions-kebab-container"
  >
    <a
      class="ag-cell-click"
      @click.self="showDetail"
    >
      <div
        v-if="showActions"
        ref="subMenuDots"
        class="dropdown sub-menu-dots"
        :class="{disabled: disabled}"
      >
        <i
          class="far fa-ellipsis-v actions-kebab"
          @click="toggleDropdown"
        />
      </div>
    </a>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'

export default {
  mixins: [Helpers, LastSessionDetail],
  data () {
    return {
      rowData: {},
      rowIndex: 0,
      disabled: false,
      showActions: false
    }
  },
  computed: {
    rowId () {
      return `row-${this.params.node.id}-actions`
    },
    menuItems () {
      const items = [{
        action: 'dischargeFromProgram',
        label: this.$t('dischargeFromProgram')
      }]
      if (this.userHasAccessToAddRespondent()) {
        items.push({
          action: 'triggerAddRespondent',
          label: this.$t('addRespondent')
        })
      }
      return items
    }
  },
  beforeMount () {
    this.rowData = this.params.data
    this.rowIndex = this.params.node.rowIndex
    this.disabled = this.detailViewOpened
    this.showActions = this.params.showActions
  },
  methods: {
    toggleDropdown () {
      // invoke method from parent
      const dropdownParams = {
        ...this.params,
        rowIndex: this.rowIndex
      }
      this.params.context.homepageGrid.toggleDropdown(dropdownParams)
    },
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    },
    closeSubMenuDots () {
      const menu = this.$refs.dotsMenu
      if (menu) {
        menu.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .actions-kebab-container a {
    .sub-menu-dots {
      right: 15px;
    }
  }
</style>
