<template>
  <div class="clinician-patients-list">
    <h1>Last 7 days</h1>
    <ag-grid-vue
      :style="gridStyleStr"
      class="ag-theme-owl"
      :column-defs="columns"
      :row-data="patientsNewResults"
      row-selection="multiple"
      animate-rows="true"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :context="context"
      :overlay-no-rows-template="noRowsOverlay"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      @bodyScroll="handleScrollEvent"
      @model-updated="onModelUpdated"
      @sort-changed="onSortChanged"
    />
    <div
      class="ag-tooltip-container"
      :class="{ visible: isTooltipVisible }"
      :style="tooltipPosition"
    />
    <div
      v-if="searchString.length > 1 && !validSearchResult"
      class="no-patients-link"
    >
      {{ $t('noPatientsFound') }}
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/assets/ag-grid/ag-theme-owl.css'
import { mapGetters, mapMutations } from 'vuex'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { GridHelpers } from '@/mixins/GridHelpers'
import { PatientsList } from '@/components/homepage/columnDefs/PatientListColumnsHelper'
import { PatientHelper } from '@/mixins/PatientHelper'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { SET_SCROLL_POSITION_BY_TAB } from '@/store/modules/user/constants.js'

export default {
  name: 'ClinicianPatientsNewResults',
  components: {
    AgGridVue
  },
  mixins: [
    Helpers,
    DateTimeHelper,
    GridHelpers,
    PatientHelper,
    PatientsList,
    LastSessionDetail,
    UserManagementComponentMixin,
    RemoteTable
  ],
  props: {
    fromPatientLayout: {}
  },
  data () {
    return {
      addRespondentToPatient: null,
      gridApi: null,
      loadingOverlay: null,
      noRowsOverlay: null,
      isTooltipVisible: false,
      tooltipCell: {},
      tooltipContent: '',
      tooltipPosition: 0,
      validSearchResult: false,
      highestRenderedRow: 0,
      gridStyleStr: '',
      defaultSortModel: [
        {
          colId: 'latest-completed-session-date',
          sort: 'desc',
          sortIndex: 0
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      patientsNewResults: 'getPatientsNewResults',
      patientsLoading: 'getLoadingGetPatient',
      uiSettings: 'getUiSettings',
      searchString: 'getSearchString',
      sortOptions: 'getSortOptions'
    }),
    gridOptions () {
      return {
        columnDefs: this.columnDefs,
        rowData: this.rowData,
        defaultColDef: this.defaultColDef,
        onFirstDataRendered: this.headerHeightSetter,
        onColumnResized: this.headerHeightSetter,
        suppressScrollOnNewData: true,
        suppressHorizontalScroll: true,
        rowHeight: 85
      }
    },
    defaultColDef () {
      return {
        flex: 1,
        resizable: false,
        autoHeight: true,
        suppressMovable: true
      }
    },
    apiParams () {
      return {
        patientSelector: 'all',
        paginate: 0
      }
    }
  },
  watch: {
    searchString (newVal) {
      this.validSearchResult = false
      this.externalFilterChanged(newVal)
    }
  },
  beforeMount () {
    this.context = { homepageGrid: this }
    this.gridStyleStr = this.getGridHeight(235)
  },
  mounted () {
  },
  created () {
    this.apiParams.patientSelector = this.uiSettings.patientSelector ? this.uiSettings.patientSelector.selected : 'my'
    if (this.patientsNewResults === undefined || this.patientsNewResults.length === 0) {
      this.$store.dispatch('GET_PATIENTS_NEW_RESULTS', this.apiParams)
    }
    this.loadingOverlay = `<div><span>${this.$t('loading')}</span><i class="fas fa-circle-notch fa-spin" /></div>`
    this.noRowsOverlay = `<div><span>${this.$t('scheduleListTable.noPatientsFound')}</span></div>`
  },
  methods: {
    ...mapMutations({
      setScrollPosition: SET_SCROLL_POSITION_BY_TAB,
      setSortOptions: 'user/SET_SORT_OPTIONS'
    }),
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      if (this.sortOptions.tab === this.homeTab) {
        this.columnApi.applyColumnState({ state: this.sortOptions.data })
      } else {
        this.columnApi.applyColumnState({ state: this.defaultSortModel })
      }
      this.gridApi.sizeColumnsToFit()
      this.headerHeightSetter()
      this.gridApi.showLoadingOverlay()
      if (this.isExternalFilterPresent) {
        this.gridApi.onFilterChanged()
      }
    },
    onFirstDataRendered (params) {
      this.scrollToRow()
      if (this.gridOptions.rowData.length) {
        this.gridApi.hideOverlay()
      }
    },
    onModelUpdated (params) {
      params.api.deselectAll()
      this.closeLastSessionDetail()
    },
    onSortChanged () {
      const sortState = this.columnApi.getColumnState()
      const updatedSortOptions = {
        tab: 'new-results',
        data: []
      }
      for (let i = 0; i < sortState.length; i++) {
        const item = sortState[i]
        updatedSortOptions.data.push({ colId: item.colId, sort: item.sort, sortIndex: item.sortIndex })
      }
      this.setSortOptions(updatedSortOptions)
    },
    openDetail (data) {
      this.openLastSessionDetail(data)
      this.hideTooltip()
    },
    refreshList () {
      this.apiParams.patientSelector = this.uiSettings.patientSelector ? this.uiSettings.patientSelector.selected : 'my'
      this.gridApi.showLoadingOverlay()
      this.$store.dispatch('GET_PATIENTS_NEW_RESULTS', this.apiParams)
    },
    resetGrid () {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/override.scss';
  .clinician-patients-list {
    @apply tw-h-full;
  }
  // TODO height of total grid needs to be refined
  h1 {
    @apply tw-text-2xl tw-text-left tw-p-0 tw-py-5 tw-pl-5 tw-m-0 tw-bg-white;
  }
  .ag-tooltip-container {
    @apply tw-absolute tw-text-left tw-border tw-border-owl-navy tw-rounded-sm tw-p-3 tw-bg-white tw-text-sm tw-font-light tw-invisible;
    &.visible {
      @apply tw-visible;
      width: 175px;
      max-height: 200px;
      overflow-y: overlay;
      z-index: 999;
    }

    span.list-clinician {
      @apply tw-block tw-pt-0.5 tw-py-0;
    }
  }
  .no-patients-link {
    -webkit-font-smoothing: antialiased;
    width: 865px;
    font-weight: 100;
    font-size: 15px;
    position: absolute;
    top: 573.5px;
    z-index: 10;
  }
</style>
