import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import { DateTimeHelper } from './DateTimeHelper'
import { DATE_SELECTOR_DATE } from '@/store/modules/user/constants.js'
import UserManagementComponentMixin from './UserManagement/UserManagementComponentMixin'

export const AppointmentsByDate = {
  mixins: [DateTimeHelper, UserManagementComponentMixin],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      scheduledPatientsByDate: 'getScheduledPatientsByDate',
      scheduledPatientsByDateLoading: 'getScheduledPatientsByDateLoading',
      homeTab: 'getHomeTab',
      date: 'getDateSelectorDate',
      settings: 'generalSettings',
      savedScrollPosition: 'getSavedScrollPositionByTab'
    }),
    isInRange () {
      return moment().isSame(this.date, this.mode)
    },
    mode () {
      return this.homeTab === 'today' ? 'day' : this.homeTab === 'week' ? 'week' : ''
    }
  },
  methods: {
    ...mapMutations({
      setDate: DATE_SELECTOR_DATE
    }),
    reset () {
      if (!this.isInRange) {
        this.setDate(moment().toDate())
      } else {
        this.scrollToDay()
      }
    },
    goBackwards () {
      this.$emit('close')
      this.setDate(moment(this.date).add(-1, this.mode).toDate())
    },
    goForward () {
      this.$emit('close')
      this.setDate(moment(this.date).add(1, this.mode).toDate())
    },
    fetchData (date) {
      if (this.mode) {
        const realMode = this.mode === 'week' ? 'isoWeek' : this.mode
        const dateStart = this.getAppointmentStartDate(date, realMode)
        const dateEnd = this.getAppointmentEndDate(date, realMode)
        const apiParams = {
          patientSelector: this.uiSettingsLoaded && this.uiSettings.patientSelector ? this.uiSettings.patientSelector.selected : 'my',
          startDate: dateStart,
          endDate: dateEnd,
          paginate: 0
        }
        if (this.scheduledPatientsByDateLoading) {
          this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'getScheduledPatientsByDate' })
          this.doDispatch(apiParams)
        } else {
          this.doDispatch(apiParams)
        }
      }
    },
    doDispatch (apiParams) {
      this.loading = true
      const loading = this.$loading.show()
      this.$store.dispatch('GET_SCHEDULED_PATIENTS_BY_DATE', apiParams).then(() => {
        if (this.isInRange && this.mode === 'week') {
          this.$nextTick().then(() => {
            this.scrollToDay()
          })
        }
      }).finally(() => {
        this.loading = false
        loading.hide()
      })
    },
    getDateId (day) {
      const date = day ? moment(day) : moment()
      return `day-${moment(date).format('YYYYMMDD')}`
    },
    scrollToDay (day) {
      const ssp = this.savedScrollPosition.find(i => i.tab === this.homeTab)
      const scrollPosition = ssp && ssp.index ? ssp.index : 0
      if (scrollPosition === 0) {
        const dayId = this.getDateId(day)
        this.$scrollTo(`#${dayId}`, { container: '.clinician-schedules-list-container', offset: 0 })
      }
    }
  },
  watch: {
    date (n) {
      this.fetchData(n)
    }
  }
}
