import AvatarCellRenderer from '@/components/homepage/cellRenderers/AvatarCellRenderer.vue'
import NameCellRenderer from '@/components/homepage/cellRenderers/NameCellRenderer.vue'
import ClinicianTeamsCellRenderer from '@/components/homepage/cellRenderers/ClinicianTeamsCellRenderer.vue'
import NextVisitCellRenderer from '@/components/homepage/cellRenderers/NextVisitCellRenderer.vue'
import NextSessionDueCellRenderer from '@/components/homepage/cellRenderers/NextSessionDueCellRenderer.vue'
import LatestCompletedSessionCellRenderer from '@/components/homepage/cellRenderers/LatestCompletedSessionCellRenderer.vue'

window.dateComparator = function dateComparator (date1, date2) {
  const date1Number = this.monthToComparableNumber(date1)
  const date2Number = this.monthToComparableNumber(date2)
  if (date1Number === null && date2Number === null) {
    return 0
  }
  if (date1Number === null) {
    return -0
  }
  if (date2Number === null) {
    return 1
  }
  return date1Number - date2Number
}

window.monthToComparableNumber = function monthToComparableNumber (date) { // eg 29/08/2004 gets converted to 20040829
  if (date === undefined || date === null || date.length !== 10) {
    return null
  }
  const yearNumber = Number.parseInt(date.substring(6, 10))
  const monthNumber = Number.parseInt(date.substring(3, 5))
  const dayNumber = Number.parseInt(date.substring(0, 2))
  return yearNumber * 10000 + monthNumber * 100 + dayNumber
}

export const PatientsList = {
  components: {
    AvatarCellRenderer,
    NameCellRenderer,
    ClinicianTeamsCellRenderer,
    NextVisitCellRenderer,
    NextSessionDueCellRenderer,
    LatestCompletedSessionCellRenderer
  },
  computed: {
    columns () {
      const columns = [{
        headerName: '',
        field: 'avatar',
        colId: 'patient-avatar',
        resizable: false,
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: 'AvatarCellRenderer'
      }, {
        headerName: this.$t('patientsTable.patient'),
        field: 'name',
        colId: 'patient-name',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        width: 380,
        cellRenderer: 'NameCellRenderer',
        cellRendererParams: { homeTab: this.homeTab },
        cellStyle: { textAlign: 'left' },
        valueGetter: params => `${params.data.full_name.toLowerCase()}`
      }, {
        headerName: this.$t('patientsTable.clinicians'),
        field: 'clinicians',
        colId: 'clinicians',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        width: 200,
        wrapText: true,
        cellRenderer: 'ClinicianTeamsCellRenderer',
        cellStyle: { textAlign: 'left' }
      }, {
        headerName: this.$t('patientsTable.nextVisit'),
        field: 'next_visit',
        colId: 'next-visit',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        cellRenderer: 'NextVisitCellRenderer',
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' },
        comparator: this.dateComparator
      }, {
        headerName: this.$t('patientsTable.lastSessionCompleted'),
        field: 'latest_completed_session_date',
        colId: 'latest-completed-session-date',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        sort: 'desc',
        resizable: false,
        cellRenderer: 'LatestCompletedSessionCellRenderer',
        cellRendererParams: { source: 'patientsList' },
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' },
        comparator: this.dateComparator,
        valueGetter: params => new Date(params.data.latest_completed_session_completed_date)
      }, {
        headerName: this.$t('patientsTable.nextSessionDue'),
        field: 'latest_session_date',
        colId: 'latest-session-date',
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        resizable: false,
        cellRenderer: 'NextSessionDueCellRenderer',
        cellRendererParams: { source: 'patientsList' },
        headerClass: 'ag-center-aligned-header',
        cellStyle: { textAlign: 'center' },
        comparator: this.dateComparator,
        // TODO next_session_status 5/None values interfere with sorting due to its value not taken into account
        valueGetter: params => new Date(params.data.next_session_date)
      }, {
        headerName: 'id',
        field: 'access_control_id',
        hide: true
      }, {
        headerName: 'id',
        field: 'mrn',
        hide: true
      }, {
        headerName: 'age',
        field: 'age',
        hide: true
      }, {
        headerName: 'justAdded',
        field: 'justAdded',
        hide: true
      }, {
        headerName: 'teams',
        field: 'teams',
        hide: true
      }, {
        headerName: 'preferred_name',
        field: 'preferred_name',
        hide: true
      }]
      const filteredColumns = (this.$store.getters.generalSettings.include_appointments_feature && this.$store.getters.generalSettings.ehr_integration_available) ? columns : columns.filter(c => c.field !== 'next_visit')
      return filteredColumns
    }
  }
}
