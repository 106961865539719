<template>
  <div
    class="ag-cell-list-item patient-list"
  >
    <a
      class="ag-cell-click"
      @click="showDetail"
    >
      <div class="patient-list-name">
        <router-link
          :to="patientRoute"
          class="name go-to-patient"
          @click.native.self.stop.prevent="setClickedRow"
        >
          {{ nameValue }}</router-link>
        <span class="demographics">{{ ageValue }} {{ genderValue }}</span>
      </div>
      <span class="patient-info-label">MRN</span>
      <span class="patient-info-value mrn">{{ mrnValue }}</span>
      <span class="patient-info-label">OWL</span>
      <span class="patient-info-value owl">{{ owlIdValue }}</span>
    </a>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { differenceInYears } from 'date-fns'
import { Helpers } from '@/mixins/Helpers'
import { SET_SCROLL_POSITION_BY_TAB } from '@/store/modules/user/constants.js'

export default {
  mixins: [Helpers],
  data () {
    return {
      rowIndex: 0,
      homeTab: '',
      nameValue: '',
      ageValue: '',
      genderValue: '',
      mrnValue: '',
      owlIdValue: '',
      ccaId: '',
      ccauId: '',
      alertCount: 0
    }
  },
  computed: {
    patientRoute () {
      const routeObj = {
        name: (this.alertCount > 0) ? 'ClinicianPatientAlerts' : 'ClinicianPatient',
        params: {
          ccaId: this.$getEncodedId(this.ccaId),
          ccauId: this.$getEncodedId(this.ccauId)
        }
      }

      if (this.search) {
        // only if a search filter is in place do we pass that onto the linked route
        routeObj.query = {
          search: this.$getEncodedId(this.search)
        }
      }
      return routeObj
    }
  },
  beforeMount () {
    this.homeTab = this.params.homeTab
    this.rowIndex = Number(this.params.node.rowIndex)
    this.nameValue = this.textTruncate(`${this.params.data.full_name}`)
    this.ageValue = this.params.age || this.calculatePatientAge(this.params.data.date_of_birth)
    this.genderValue = this.params.data.gender.toUpperCase()
    this.mrnValue = this.params.data.mrn
    this.owlIdValue = this.params.data.access_control_id
    this.ccaId = this.params.data.client_clinic_assignment_id || this.params.data.assignment_id // property name differs depending on endpoint ¯\_(ツ)_/¯
    this.ccauId = this.params.data.client_clinic_assignment_user_id
    this.alertCount = this.params.data.alert_count || this.params.data.active_alert_count // property name differs depending on endpoint ¯\_(ツ)_/¯
  },
  methods: {
    ...mapMutations({
      setScrollPosition: SET_SCROLL_POSITION_BY_TAB
    }),
    setClickedRow () {
      if (this.homeTab === 'week') {
        const element = document.querySelector('.clinician-schedules-list-container')
        const pos = element ? element.scrollTop : 0
        this.setScrollPosition({ tab: this.homeTab, index: pos })
      } else {
        this.setScrollPosition({ tab: this.homeTab, index: this.rowIndex })
      }
    },
    calculatePatientAge (dateOfBirth) {
      const formattedDOB = new Date(dateOfBirth)
      return formattedDOB ? differenceInYears(new Date(), formattedDOB) : null
    },
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    },
    textTruncate (name, length, end) {
      if (length == null) {
        length = 45
      }
      if (end == null) {
        end = '...'
      }
      if (name.length > length) {
        return name.substring(0, length - end.length) + end
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss">
  .ag-theme-owl {
    .patient-list {
      font-size: 15px;
      font-weight: 100;
      .patient-list-name {
        line-height: 1.2;
        padding-top: 6px;
        white-space: normal;
        word-break: break-all;
        a, a:link, a:active, a:hover {
          font-family: "MuseoSans_700";
          font-weight: bold;
          color: #66758f;
          margin-right: 12px;
          text-align: left;
          &:hover {
            text-decoration: underline;
          }
        }
        .demographics {
          font-family: MuseoSans_300;
          font-weight: 300;
          text-transform: capitalize;
          padding-left: 10px;
          display: inline-block;
        }
      }
      .patient-info-label {
        font-size: 12px;
      }
    }
  }
</style>
