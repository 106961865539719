<template>
  <div
    class="ag-cell-list-item"
  >
    <a
      class="ag-cell-click cell-centered"
      @click="showDetail"
    >
      <span
        v-if="isCompletedSession && latestSessionDate"
        class="latest-session-item"
      >
        {{ latestSessionDate }}
      </span>
      <div v-else>
        {{ $t('none') }}
      </div>
    </a>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { SESSION_STATUS_COMPLETED } from '@/data/lastSessionStatus'
export default {
  name: 'LatestCompletedSessionCellRenderer',
  mixins: [DateTimeHelper],
  data () {
    return {
      data: null,
      source: ''
    }
  },
  computed: {
    status () {
      // Since the schedule list has a different data format we are extracting status in 2 different ways
      switch (this.source) {
        case 'scheduleListTable':
          return this.data.latest_session_data && this.data.latest_session_data.status ? parseInt(this.data.latest_session_data.status) : null
        case 'patientDetail':
          return parseInt(this.data.status)
        case 'patientsByProgram':
        case 'patientsList':
        case 'scheduleListTableByDudeDate':
          return this.data.latest_completed_session_status ? parseInt(this.data.latest_completed_session_status) : null
        default:
          return null
      }
    },
    isCompletedSession () {
      return this.status && this.status === SESSION_STATUS_COMPLETED
    },
    latestSessionDate () {
      switch (this.source) {
        case 'scheduleListTable':
          return this.$toLocal(this.data.latest_session_data.completed_date, 'MMM DD, YYYY')
        case 'patientDetail':
          return this.$toLocal(this.data.completed_date, 'MMM DD, YYYY')
        case 'patientsByProgram':
        case 'patientsList':
        case 'scheduleListTableByDudeDate':
          return this.$toLocal(this.data.latest_completed_session_completed_date, 'MMM DD, YYYY')
        default:
          return null
      }
    }
  },
  beforeMount () {
    this.data = this.params.data
    this.source = this.params.source
  },
  methods: {
    showDetail () {
      // invoke method from parent
      this.params.context.homepageGrid.openDetail(this.params.data)
    }
  }
}
</script>
