<template>
  <div
    class="clinician-schedules-list-table"
    :class="{'weekly-table': homeTab === 'week'}"
  >
    <DateTitle
      v-if="homeTab === 'week'"
      :date="date"
    />
    <ag-grid-vue
      v-if="homeTab === 'week'"
      :ref="$attrs.id"
      style="height: 90%;"
      class="ag-theme-owl"
      :column-defs="columns"
      :context="context"
      :row-data="measures"
      row-selection="multiple"
      animate-rows="true"
      dom-layout="autoHeight"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :overlay-no-rows-template="noRowsOverlay"
      @grid-ready="onGridReady"
      @column-resized="onGridColumnsChanged"
      @model-updated="onModelUpdated"
      @row-selected="onRowSelected"
    />
    <ag-grid-vue
      v-else
      :ref="$attrs.id"
      :style="gridStyleStr"
      class="ag-theme-owl"
      :column-defs="columns"
      :context="context"
      :row-data="measures"
      row-selection="multiple"
      animate-rows="true"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :overlay-no-rows-template="noRowsOverlay"
      @grid-ready="onGridReady"
      @column-resized="onGridColumnsChanged"
      @bodyScroll="handleScrollEvent"
      @model-updated="onModelUpdated"
    />
    <div
      class="ag-tooltip-container"
      :class="{ visible: isTooltipVisible }"
      :style="tooltipPosition"
    />
    <div
      v-if="searchString.length > 1 && !validSearchResult && homeTab === 'today' && hasMeasuresDue"
      class="no-patients-link"
    >
      {{ $t('noPatientsFound') }}
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/assets/ag-grid/ag-theme-owl.css'
import { mapGetters, mapMutations } from 'vuex'
import { USER_TYPE_STAFF } from '@/data/permissions'
import { PatientsByDueDate } from '@/components/homepage/columnDefs/PatientsByDueDateColumnsHelper'
import DateTitle from '@/components/common/DateTitle'
import { GridHelpers } from '@/mixins/GridHelpers'
import { SET_SCROLL_POSITION_BY_TAB } from '@/store/modules/user/constants.js'

export default {
  name: 'PatientsByDueDateListSingleDay',
  components: {
    AgGridVue,
    DateTitle
  },
  mixins: [GridHelpers, PatientsByDueDate],
  props: ['date', 'measures', 'dayIndex'],
  data () {
    return {
      userTypeStaff: USER_TYPE_STAFF,
      rowActive: null,
      loadingOverlay: null,
      noRowsOverlay: null,
      isTooltipVisible: false,
      tooltipPosition: 0,
      tooltipCell: {},
      validSearchResult: false,
      gridStyleStr: '',
      defaultSortModel: [
        {
          colId: 'visit-date-time',
          sort: 'asc',
          sortingOrder: ['desc', 'asc'],
          sortIndex: 0
        },
        {
          colId: 'patient-name',
          sort: 'asc',
          sortingOrder: ['desc', 'asc'],
          sortIndex: 1
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      detailViewOpened: 'getDetailVewOpened',
      user: 'loggedInUser',
      homeTab: 'getHomeTab',
      settings: 'generalSettings',
      searchString: 'getSearchString'
    }),
    gridOptions () {
      return {
        columnDefs: this.columnDefs,
        rowData: this.rowData,
        defaultColDef: this.defaultColDef,
        headerHeight: this.headerHeight,
        suppressHorizontalScroll: true,
        rowHeight: 85
      }
    },
    defaultColDef () {
      return {
        flex: 1,
        resizable: false,
        autoHeight: true,
        sortable: false,
        suppressMovable: true
      }
    },
    hasMeasuresDue () {
      return this.measures.length > 0
    },
    headerHeight () {
      return this.homeTab === 'week' ? this.dayIndex > 0 ? 0 : 0 : ''
    },
    gridHeight () {
      return this.homeTab === 'today' ? 'height: 92%' : this.dayIndex === 0 ? `height: ${this.headerHeight + 50}px` : 'height: 50px'
    }
  },
  watch: {
    searchString (newVal) {
      if (this.hasMeasuresDue) {
        if (this.gridApi) {
          this.gridApi.hideOverlay()
        }
        this.validSearchResult = false
        this.externalFilterChanged(newVal)
        if (this.homeTab === 'week' && this.searchString.length > 1 && !this.validSearchResult && this.gridApi) {
          this.gridApi.showNoRowsOverlay()
        }
      }
    }
  },
  beforeMount () {
    this.context = { homepageGrid: this }
  },
  mounted () {
    this.gridStyleStr = this.getGridHeight(200)
  },
  created () {
    this.loadingOverlay = `<div><span>${this.$t('loading')}</span><i class="fas fa-circle-notch fa-spin" /></div>`
    this.noRowsOverlay = `<div><span>${this.$t('scheduleListTable.noPatientsFound')}</span></div>`
  },
  methods: {
    ...mapMutations({
      setScrollPosition: SET_SCROLL_POSITION_BY_TAB
    }),
    openDetail (params) {
      this.$emit('openLastSessionDetail', params)
      this.hideTooltip()
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.gridApi.deselectAll()
      this.columnApi.applyColumnState({ state: this.defaultSortModel })
      if (this.isExternalFilterPresent) {
        this.gridApi.onFilterChanged()
      }
      if (this.homeTab === 'week') {
        this.scrollToPosition()
      } else {
        this.scrollToRow()
      }
      if (this.homeTab === 'week' && this.searchString.length > 1 && !this.validSearchResult) {
        this.gridApi.showNoRowsOverlay()
      }
    },
    onGridColumnsChanged () {
      this.$emit('gridReady')
    },
    onModelUpdated (params) {
      params.api.deselectAll()
      this.$emit('close', params)
    },
    onRowSelected (params) {
      if (params.node.isSelected() && this.homeTab === 'week') {
        this.$emit('resetGrid', this.dayIndex)
      }
    },
    resetGrid () {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    }
  }
}
</script>
<style lang="scss">
.grid-container {
  .clinician-schedules-list {
    .clinician-schedules-list-table {
      margin-top: 2px;

      &.weekly-table {
        margin-top: 0 !important;
      }
    }
  }

  .no-patients-link {
    -webkit-font-smoothing: antialiased;
    width: 865px;
    font-weight: 100;
    font-size: 15px;
    position: absolute;
    top: 573.5px;
    z-index: 10;
  }
}
</style>
