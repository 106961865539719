<template>
  <div class="home-clinician">
    <ClinicianHomeTabs
      :show-patient-selector="true"
      :hide-status-selector="true"
      :placeholder="searchPlaceholder"
      @filtersChanged="handlePatientSelectorChange"
      @searching="searching"
    />
    <div
      v-if="uiSettingsLoaded"
      class="home-content"
      :class="tabClasses"
    >
      <PatientsByDayOrWeek
        v-if="homeTab === 'today' && appointmentView"
        ref="dayList"
        :key="componentKey"
        :default="true"
        :sidebar="false"
        :due-date-view="dueDateView"
        :show-today-and-this-week="showTodayAndThisWeek"
        @openLastSessionDetail="openLastSessionDetail"
        @close="resetGridAndClosePanel"
      />
      <PatientsByDayOrWeek
        v-if="homeTab === 'week' && appointmentView"
        ref="weekList"
        :key="componentKey"
        :default="true"
        :sidebar="false"
        :due-date-view="dueDateView"
        :show-today-and-this-week="showTodayAndThisWeek"
        @openLastSessionDetail="openLastSessionDetail"
        @close="resetGridAndClosePanel"
      />
      <PatientsByProgram
        v-if="homeTab === 'programs'"
        ref="patientsByProgram"
        :from-patient-layout="fromPatientLayout"
        @addRespondentFromTable="addRespondentFromTable"
        @close="resetGridAndClosePanel"
      />
      <ClinicianPatientsNewResults
        v-if="homeTab === 'new-results'"
        id="new-results"
        ref="newResults"
        :from-patient-layout="fromPatientLayout"
        @close="resetGridAndClosePanel"
      />
    </div>
    <overlay
      v-if="routematch || addRespondentToPatient"
      :prompt-before-close="promptBeforeClose"
      :hide-close-button="addMeasuresToRespondentOverview"
      @close="closeOverlay"
    >
      <template slot="content">
        <router-view
          v-if="routematch"
          @patient-discharged="refreshPrograms"
        />
        <clinician-respondent-form-wrapper
          v-else
          :cca-id="addRespondentToPatient"
          @finished="finished"
        />
      </template>
    </overlay>
    <detail-view
      v-if="detailViewOpened && lastSessionDetail"
      @close="resetGridAndClosePanel"
    >
      <template slot="title">
        <div class="latestDetailTitle">
          <strong>{{ lastSessionDetail.first_name }}'s {{ $t('latestMeasures') }}</strong>
        </div>
      </template>
      <template slot="content">
        <LastOwlSessionDetail
          :patient="lastSessionDetail"
          :mutation-text="'programs'"
        />
        <div
          v-if="showAddRespondent"
          class="last-owl-session-detail-view-add-respondent"
        >
          <button
            class="btn btn-sqare btn-outline-secondary btn-add-respondent btn-sm"
            type="button"
            @click="addRespondentFromDetail"
          >
            <span class="sign-in-text">{{ $t('addRespondent') }}</span>
          </button>
        </div>
      </template>
    </detail-view>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { GridHelpers } from '@/mixins/GridHelpers'
import ClinicianHomeTabs from '@/components/homepage/ClinicianHomeTabs'
import PatientsByDayOrWeek from '@/components/homepage/PatientsByDayOrWeek'
import PatientsByProgram from '@/components/homepage/PatientsByProgram'
import ClinicianPatientsNewResults from '@/components/homepage/ClinicianPatientsNewResults'
import Overlay from '@/components/clinician/Overlay'
import ClinicianRespondentFormWrapper from '@/components/clinician/patients/ClinicianRespondentFormWrapper'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import DetailView from '@/components/common/DetailView'
import LastOwlSessionDetail from '@/components/common/LastOwlSessionDetail'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'ClinicianHomepageNew',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromPatientLayout = from.matched.some(r => r.name === 'PatientLayout')
    })
  },
  async beforeRouteLeave (to, from, next) {
    // wait for scroll position to be set by NameCellRenderer before rerouting
    await setTimeout(() => {
      this.$nextTick()
      next()
    }, 100)
  },
  components: {
    ClinicianHomeTabs,
    PatientsByDayOrWeek,
    PatientsByProgram,
    ClinicianPatientsNewResults,
    Overlay,
    ClinicianRespondentFormWrapper,
    DetailView,
    LastOwlSessionDetail
  },
  mixins: [Helpers, GridHelpers, LastSessionDetail, UserManagementComponentMixin],
  data () {
    return {
      search: '',
      componentKey: 0,
      addRespondentToPatient: null,
      fromPatientLayout: false,
      isInRange: true
    }
  },
  computed: {
    ...mapGetters({
      homeTab: 'getHomeTab',
      settings: 'generalSettings',
      uiSettingsLoaded: 'getUiSettingsLoaded',
      savedScrollPosition: 'getSavedScrollPositionByTab'
    }),
    dueDateView () {
      return this.appointmentView && !this.settings.ehr_integration_available
    },
    showTodayAndThisWeek () {
      return this.appointmentView || this.dueDateView
    },
    routematch () {
      const routeNameAllowed = [
        'ClinicianPatientsByProgramAddMeasureToPatientOverview',
        'ClinicianPatientsByProgramAddMeasureToPatient',
        'ClinicianAddPatientByProgram',
        'ClinicianDischargeFromProgram',
        'ClinicianHomePageAddMeasure',
        'ClinicianHomePageMeasuresAddedOverview',
        'ClinicianAddProgramToPatient',
        'AddPatientChooseProgram'
      ]
      return routeNameAllowed.indexOf(this.$route.name) !== -1
    },
    searchPlaceholder () {
      return this.$t('searchPatients')
    },
    addMeasuresToRespondentOverview () {
      return this.$route.name === 'ClinicianPatientsByProgramAddMeasureToPatientOverview'
    },
    tabClasses () {
      return {
        'single-day': this.homeTab === 'today',
        'weekly-open': this.homeTab === 'week',
        'new-results': this.homeTab === 'new-results',
        programs: this.homeTab === 'programs'
      }
    }
  },
  watch: {
    homeTab: {
      handler (newValue, oldValue) {
        if (oldValue !== '') {
          this.reRenderComponent()
        }
      }
    }
  },
  created () {
    this.closeLastSessionDetail()
    this.$store.commit('user/SET_DETAIL_TO_OPEN', null)
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
  },
  mounted () {
    this.setDefaultView()
  },
  destroyed () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
  },
  methods: {
    ...mapMutations({
      SET_HOME_TABS: 'user/SET_HOME_TABS',
      SET_SEARCH_STRING: 'user/SET_SEARCH_STRING'
    }),
    reRenderComponent () {
      this.componentKey += 1
    },
    refreshPrograms () {
      this.$nextTick(() => {
        this.$refs.patientsByProgram.refreshList()
      })
    },
    finished () {
      if (this.lastSessionDetail) {
        this.fetchTeamLastSession({ ...this.lastSessionDetail })
      }
      this.addRespondentToPatient = null
    },
    closeOverlay () {
      this.finished()
      this.$router.push({ name: 'Home', query: Object.assign(this.$route.query, { homeTab: this.homeTab }) })
    },
    searching (search) {
      this.search = search
      this.SET_SEARCH_STRING(search)
    },
    promptBeforeClose () {
      return new Promise((resolve) => {
        const promptOptions = {
          title: this.$t('areYouSureToExit'),
          message: this.$t('updatesNotSaved'),
          cancelButton: this.$t('noResume'),
          okButton: this.$t('yesExit')
        }
        this.$promptBeforeAction(promptOptions, () => {
          if (typeof this.afterClose === 'function') {
            this.afterClose()
          }
          resolve()
        })
      })
    },
    refreshView () {
      this.handlePatientSelectorChange()
    },
    getComponentRefByHometab () {
      let component = null
      switch (this.homeTab) {
        case 'today':
          component = this.$refs.dayList
          break
        case 'week':
          component = this.$refs.weekList
          break
        case 'programs':
          component = this.$refs.patientsByProgram
          break
        case 'new-results':
          component = this.$refs.newResults
          break
      }
      return component
    },
    handlePatientSelectorChange () {
      const component = this.getComponentRefByHometab()
      if (component) {
        this.closeLastSessionDetail()
        component.refreshList()
      }
    },
    setDefaultView () {
      if (Object.keys(this.$route.query).length === 0 && !this.$route.path.includes('add')) {
        // homeTab queryString is not set
        if (this.homeTab !== '') {
          // homeTab state is set. redirect based on that.
          this.$router.push({ name: 'ClinicianHomepage', query: { homeTab: this.homeTab } })
        } else {
          // homeTab state is not set. redirect according to access level
          if (this.userHasAccessToTodayAndWeekTabs && this.appointmentView) {
            this.$router.push({ name: 'ClinicianHomepage', query: { homeTab: 'today' } })
          } else if (this.programsView && (this.userHasAccessToMyPrograms() || this.userHasAccessToAllPrograms())) {
            this.$router.push({ name: 'ClinicianHomepage', query: { homeTab: 'programs' } })
          } else {
            this.$router.push({ name: 'ClinicianHomepage', query: { homeTab: 'new-results' } })
          }
        }
      }
    },
    hideGridTooltip () {
      if (this.homeTab === 'week') {
        this.hideTooltip()
      }
    },
    resetGridAndClosePanel () {
      const component = this.getComponentRefByHometab()
      if (component) {
        component.resetGrid()
      }
      this.closeLastSessionDetail()
    }
  }
}
</script>
<style lang="scss">
  .home-content {
    overflow: hidden;
    height: calc(100vh - 138px);
    &.single-day {
      height: calc(100vh - 130px);
    }
    &.weekly-open {
      height: calc(100vh - 100px);
    }
  }
</style>
