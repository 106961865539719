<template>
  <div class="clinician-schedules-list-container">
    <div
      class="clinician-schedules-list"
      :class="{'ehr-disabled': dueDateView}"
    >
      <div id="week-container">
        <PatientsByDueDateListSingleDay
          v-for="(day, index) in measures"
          :id="getDateId(index)"
          ref="singleDayMeasures"
          :key="index"
          :date="index"
          :measures="day"
          :day-index="getIndex(index)"
          @openLastSessionDetail="openLastSessionDetail"
          @gridReady="setGridReady"
          @close="closeLastSessionDetail"
          @resetGrid="resetGrid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PatientsByDueDateListSingleDay from '@/components/homepage/patientsByDueDate/PatientsByDueDateListSingleDay'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import moment from 'moment'
import { GridHelpers } from '@/mixins/GridHelpers'

export default {
  name: 'PatientsByDueDateListByWeek',
  components: {
    PatientsByDueDateListSingleDay
  },
  mixins: [DateTimeHelper, GridHelpers],
  props: {
    measureData: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      dueDateView: true,
      scrollContainer: null
    }
  },
  computed: {
    ...mapGetters({
      savedScrollPosition: 'getSavedScrollPositionByTab'
    }),
    measures () {
      return this.measureData.days
    }
  },
  mounted () {
    this.scrollContainer = document.querySelector('.clinician-schedules-list-container')
    this.scrollContainer.addEventListener('scroll', this.handleScrollEvent)
  },
  destroyed () {
    this.scrollContainer.removeEventListener('scroll', this.handleScrollEvent)
  },
  methods: {
    openLastSessionDetail (patient) {
      this.$emit('openLastSessionDetail', patient)
    },
    closeLastSessionDetail (params, dayIndex) {
      this.resetGrid(dayIndex)
      this.$emit('close', params)
    },
    getDateId (day) {
      const date = day ? moment(day) : moment()
      return `day-${moment(date).format('YYYYMMDD')}`
    },
    getIndex (key) {
      const keys = Object.keys(this.measures)
      return keys.indexOf(key)
    },
    setGridReady () {
      this.$emit('gridReady')
    },
    resetGrid (dayIndex) {
      this.$refs.singleDayMeasures.forEach(item => {
        if (dayIndex === undefined || item.dayIndex !== dayIndex) {
          item.resetGrid()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.body-clinician-layout {
  .clinician-schedules-list {
    height: auto;
    padding-bottom: 50px;
  }
}
.grid-container {
  clinician-schedules-list {
    &.ehr-disabled {
      background-color: #fff;
    }
  }
}
.clinician-schedules-list-container {
  overflow-y: scroll;
  height: calc(100% - 150px);
  background-color: #FFF;
}
</style>
