<template>
  <div class="date-title">
    <div class="date">
      <span class="day-text"> {{ formattedDayText }}</span> <span class="day">{{ formattedDate }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateTitle',
  props: ['date'], // eslint-disable-line vue/require-prop-types
  computed: {
    formattedDate () {
      return moment(this.date).format('MMM DD').toUpperCase()
    },
    formattedDayText () {
      return moment(this.date).format('ddd').toUpperCase()
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  @import './../../assets/styles/override.scss';
  .date-title {
    padding: 15px;
    display: flex;
    background: $white;
    align-items: center;
    justify-content: flex-start;
    color: $navy-blue;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    .date {
      font-size: 21px;
      .day {
        font-weight: 300;
      }
      .day-text {
        font-weight: bold;
      }
    }
  }
</style>
