<template>
  <div class="clinician-schedules-list-container">
    <div class="clinician-schedules-list">
      <div id="week-container">
        <ClinicianSchedulesListSingleDay
          v-for="(day, index) in appointments"
          :id="getDateId(index)"
          :key="index"
          ref="singleDayAppointments"
          :date="index"
          :appointments="day"
          :day-index="getIndex(index)"
          @openLastSessionDetail="openLastSessionDetail"
          @gridReady="setGridReady"
          @close="closeLastSessionDetail"
          @resetGrid="resetGrid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClinicianSchedulesListSingleDay from '@/components/homepage/schedules/ClinicianSchedulesListSingleDay'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import moment from 'moment'
import { GridHelpers } from '@/mixins/GridHelpers'

export default {
  name: 'ClinicianSchedulesListByWeek',
  components: {
    ClinicianSchedulesListSingleDay
  },
  mixins: [DateTimeHelper, GridHelpers],
  props: {
    appointmentData: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      appointmentView: true,
      scrollContainer: null
    }
  },
  computed: {
    appointments () {
      return this.appointmentData.days
    }
  },
  mounted () {
    this.scrollContainer = document.querySelector('.clinician-schedules-list-container')
    this.scrollContainer.addEventListener('scroll', this.handleScrollEvent)
  },
  destroyed () {
    this.scrollContainer.removeEventListener('scroll', this.handleScrollEvent)
  },
  methods: {
    openLastSessionDetail (patient) {
      this.$emit('openLastSessionDetail', patient)
    },
    closeLastSessionDetail (params, dayIndex) {
      this.resetGrid(dayIndex)
      this.$emit('close', params)
    },
    getDateId (day) {
      const date = day ? moment(day) : moment()
      return `day-${moment(date).format('YYYYMMDD')}`
    },
    getIndex (key) {
      const keys = Object.keys(this.appointments)
      return keys.indexOf(key)
    },
    setGridReady () {
      this.$emit('gridReady')
    },
    resetGrid (dayIndex) {
      this.$refs.singleDayAppointments.forEach(item => {
        if (dayIndex === undefined || item.dayIndex !== dayIndex) {
          item.resetGrid()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.body-clinician-layout {
  .clinician-schedules-list {
    height: auto;
    padding-bottom: 50px;
  }
}
.clinician-schedules-list-container {
  overflow-y: scroll;
  height: calc(100% - 150px);
  background-color: #FFF;
}
</style>
