<template>
  <div class="clinician-patients-list">
    <div
      class="programs-dropdown-container"
    >
      <owl-button-dropdown
        :key="programKey"
        :class="{ loading: !allDataLoaded }"
        :disabled="!allDataLoaded"
        :auto-toggle="true"
        :button-label="buttonLabel"
        :selected="selected"
        size="xl"
      >
        <owl-option
          v-if="!programsLoading"
          class="owl-dropdown-option"
          :items="programs"
          :selected="selected"
          @update-selected="updateSelected"
        />
      </owl-button-dropdown>
    </div>
    <ag-grid-vue
      v-if="programIsSelected"
      :style="gridStyleStr"
      class="ag-theme-owl"
      animate-rows="true"
      row-selection="multiple"
      :column-defs="columns"
      :context="context"
      :grid-options="gridOptions"
      :does-external-filter-pass="doesExternalFilterPass"
      :is-external-filter-present="isExternalFilterPresent"
      :overlay-loading-template="loadingOverlay"
      :overlay-no-rows-template="noRowsOverlay"
      :row-data="patientData.patients"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdated"
      @sort-changed="onSortChanged"
      @filter-changed="onFilterChanged"
    />
    <div
      class="ag-tooltip-container"
      :class="{ visible: isTooltipVisible }"
      :style="tooltipPosition"
    />
    <div
      ref="actionsDropdown"
      class="actions-dropdown-container"
      :class="{ visible: isDropdownVisible }"
      :style="dropdownPosition"
    >
      <span
        class="sub-menu-dots-title"
      >Actions</span>
      <a
        v-for="(menuItem, index) in menuItems"
        :key="index"
        class="dropdown-item"
        @click="hideAndExecute(menuItem.action, menuItem.params)"
      >{{ menuItem.label }}</a>
    </div>
    <detail-view
      v-if="detailViewOpened && lastSessionDetail"
      :title="$t('detail')"
      @close="closeLastSessionDetail"
    >
      <template slot="content">
        <LastOwlSessionDetail
          :patient="lastSessionDetail"
          :mutation-text="'programs'"
        />
        <div
          v-if="showAddRespondent"
          class="last-owl-session-detail-view-add-respondent"
        >
          <button
            class="btn btn-sm btn-outline-secondary btn-add-respondent"
            type="button"
            @click="addRespondentFromDetail"
          >
            <span class="sign-in-text">{{ $t('addRespondent') }}</span>
          </button>
        </div>
      </template>
    </detail-view>
    <div
      v-if="searchString.length > 1 && !validSearchResult"
      class="no-patients-link"
    >
      {{ $t('noPatientsFound') }}
      <router-link
        class="name"
        :to="{ name: 'ClinicianPatientsList', query: {filter: searchString} }"
      >
        {{ $t('searchAllPatients') }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { OwlButtonDropdown, OwlOption } from 'owl-components'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import '@/assets/ag-grid/ag-theme-owl.css'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { PatientHelper } from '@/mixins/PatientHelper'
import { Helpers } from '@/mixins/Helpers'
import { GridHelpers } from '@/mixins/GridHelpers'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import DetailView from '@/components/common/DetailView'
import LastOwlSessionDetail from '@/components/common/LastOwlSessionDetail'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { ProgramList } from '@/components/homepage/columnDefs/ProgramListColumnsHelper'
import { GET_PROGRAM_LIST_BY_ROLE } from '@/store/modules/programs/constants.js'
import { GET_PATIENTS_BY_PROGRAM } from '@/store/modules/patients/constants'
import { SET_SCROLL_POSITION_BY_TAB } from '@/store/modules/user/constants.js'

export default {
  name: 'PatientsByProgram',
  components: {
    AgGridVue,
    DetailView,
    LastOwlSessionDetail,
    OwlButtonDropdown,
    OwlOption
  },
  mixins: [PatientHelper, Helpers, GridHelpers, LastSessionDetail, ProgramList, UserManagementComponentMixin],
  props: ['fromPatientLayout'],
  data () {
    return {
      myProgramListLoading: true,
      allProgramListLoading: true,
      myProgramListLoaded: false,
      allProgramListLoaded: false,
      addRespondentToPatient: null,
      loadingOverlay: null,
      initialRowData: [],
      selected: {},
      gridApi: null,
      gridReady: false,
      isTooltipVisible: false,
      tooltipCell: {},
      tooltipPosition: 0,
      dropdownPosition: 0,
      dropdownContainer: '',
      isDropdownVisible: false,
      dropdownActionAcp: null,
      dropdownActionRowId: 0,
      rowIndex: 0,
      buttonLabel: this.$t('programFilter.programs'),
      programKey: 0,
      isGridLoading: true,
      validSearchResult: false,
      gridStyleStr: '',
      defaultSortModel: [
        {
          colId: 'patient-name',
          sort: 'asc',
          sortIndex: 0
        }
      ],
      overlayIsShowing: false,
      numberOfRows: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser',
      programsTab: 'getHomeTab',
      backRoute: 'getSinglePatientReferrerRoute',
      detailViewOpened: 'getDetailVewOpened',
      patientData: 'getPatientsByProgram',
      selectedProgram: 'getSelectedProgram',
      patientsLoading: 'getPatientsLoading',
      programs: 'getProgramsByRole',
      programsLoading: 'getProgramsByRoleLoading',
      searchString: 'getSearchString',
      sortOptions: 'getSortOptions'
    }),
    gridOptions () {
      return {
        columnDefs: this.columnDefs,
        rowData: this.rowData,
        rowHeight: 85,
        defaultColDef: this.defaultColDef,
        suppressHorizontalScroll: true
      }
    },
    defaultColDef () {
      return {
        flex: 1,
        resizable: false,
        autoHeight: true,
        suppressMovable: true
      }
    },
    programIsSelected () {
      return Object.keys(this.selectedProgram).length > 0
    },
    allDataLoaded () {
      return !this.programsLoading && !this.isGridLoading && !this.patientsLoading
    },
    menuItems () {
      const items = [{
        action: 'dischargeFromProgram',
        label: this.$t('dischargeFromProgram')
      }]
      if (this.userHasAccessToAddRespondent()) {
        items.push({
          action: 'addRespondent',
          label: this.$t('addRespondent')
        })
      }
      return items
    }
  },
  watch: {
    programsTab () {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
      this.closeLastSessionDetail()
    },
    gridReady (newVal) {
      if (newVal && this.programIsSelected) {
        this.getPatientData()
      }
    },
    selectedProgram (newVal, oldVal) {
      if (this.programIsSelected && oldVal !== newVal) {
        this.resetScrollPosition(this.homeTab)
        this.getPatientData()
      }
    },
    $route (newVal) {
      if (newVal.query.justAdded || newVal.query.justRemoved) {
        this.getPatientData()
      }
    },
    patientData (data) {
      if (Object.keys(this.patientData).length) {
        this.gridApi.setRowData(this.patientData.patients)
      }
    },
    programs (newVal) {
      const programToSet = this.programIsSelected ? this.selectedProgram : newVal.find(item => item.uuid !== null)
      this.updateSelected(programToSet)
    },
    searchString (newVal) {
      this.validSearchResult = false
      this.externalFilterChanged(newVal)
      if (this.patientData.patients.length === 0 && !this.validSearchResult) {
        if (newVal === '') {
          this.gridApi.showNoRowsOverlay()
        } else {
          this.gridApi.hideOverlay()
        }
      }
    }
  },
  beforeMount () {
    this.context = { homepageGrid: this }
  },
  mounted () {
    this.gridStyleStr = this.getGridHeight(235)
    this.dropdownContainer = document.querySelector('.actions-dropdown-container')
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
    this.getProgramsList()
    this.loadingOverlay = `<div><span>${this.$t('loading')}</span><i class="fas fa-circle-notch fa-spin" /></div>`
    this.noRowsOverlay = `<div><span>${this.$t('scheduleListTable.noPatientsFound')}</span></div>`
  },
  destroyed () {
    if (this.gridApi) {
      this.gridApi.deselectAll()
    }
    this.closeLastSessionDetail()
  },
  methods: {
    ...mapActions({
      getPatients: GET_PATIENTS_BY_PROGRAM
    }),
    ...mapMutations({
      setSelectedProgram: 'programs/SET_SELECTED_PROGRAM',
      setSortOptions: 'user/SET_SORT_OPTIONS',
      setScrollPosition: SET_SCROLL_POSITION_BY_TAB,
      reloadPatientsInAllPrograms: 'reloadPatientsInAllPrograms'
    }),
    getPatientData () {
      this.isGridLoading = true
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay()
      }
      const apiParams = {
        uuid: this.selectedProgram.uuid,
        paginate: 0
      }
      this.$store.dispatch('GET_PATIENTS_BY_PROGRAM', apiParams).then((response) => {
        this.numberOfRows = Object.keys(this.patientData.patients).length
        this.isGridLoading = false
        if (this.searchString && !this.validSearchResult) {
          this.gridApi.hideOverlay()
        }
        this.scrollToRow()
      })
    },
    addRespondent () {
      this.$emit('addRespondentFromTable', this.dropdownActionAcp)
    },
    onGridReady (params) {
      this.gridReady = true
      this.gridApi = params.api
      this.gridApi.showLoadingOverlay()
      this.columnApi = params.columnApi
      this.gridApi.sizeColumnsToFit()
      this.headerHeightSetter()
      if (this.sortOptions.tab === this.homeTab) {
        this.columnApi.applyColumnState({ state: this.sortOptions.data })
      } else {
        this.columnApi.applyColumnState({ state: this.defaultSortModel })
      }
      if (this.isExternalFilterPresent) {
        this.gridApi.onFilterChanged()
      }
      this.overlayIsShowing = this.gridOptions.api.overlayWrapperComp.activeOverlay
    },
    onModelUpdated (params) {
      params.api.deselectAll()
      this.closeLastSessionDetail()
    },
    onSortChanged () {
      const sortState = this.columnApi.getColumnState()
      const updatedSortOptions = {
        tab: 'programs',
        data: []
      }
      for (let i = 0; i < sortState.length; i++) {
        const item = sortState[i]
        updatedSortOptions.data.push({ colId: item.colId, sort: item.sort, sortIndex: item.sortIndex })
      }
      this.setSortOptions(updatedSortOptions)
    },
    onFilterChanged () {
      this.numberOfRows = this.gridOptions.api.getModel().rootNode.childrenAfterFilter.length
    },
    openDetail (params) {
      this.openLastSessionDetail(params)
      this.hideTooltip()
    },
    headerHeightSetter () {
      const padding = 10
      const height = this.headerHeightGetter() + padding
      this.gridOptions.api.setHeaderHeight(height)
    },
    getProgramsList () {
      this.$store.dispatch(GET_PROGRAM_LIST_BY_ROLE)
    },
    updateSelected (selected) {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
      this.$emit('close')
      if (selected) {
        this.selected = selected
        this.reRenderComponent()
      } else {
        selected = {
          name: 'No Programs',
          uuid: 'null',
          isHeader: false
        }
      }
      this.setSelectedProgram('')
      this.setSelectedProgram(selected)
    },
    reRenderComponent () {
      this.buttonLabel = this.selected.name
      this.programKey += 1
    },
    refreshList () {
      this.$nextTick(() => {
        this.getPatientData()
      })
    },
    resetGrid () {
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    },
    toggleDropdown (params) {
      this.rowIndex = params.rowIndex
      if (this.rowIndex === this.numberOfRows - 1) {
        // last row will be deleted on next load, so the length will be 1 less.
        // set the scroll position to current length - 2, and the new zero-based index will be correct
        this.rowIndex = this.numberOfRows - 2
      }
      this.dropdownActionAcp = params.data
      this.dropdownActionRowId = params.node.id
      this.isDropdownVisible = !this.isDropdownVisible
      if (this.isDropdownVisible) {
        this.showDropdown(params)
      }
    },
    showDropdown (data) {
      this.hideDropdown()
      // determine the position of the dropdown based on the clicked row
      const idString = `row-${data.node.id}-actions`
      const rowElement = document.getElementById(idString)
      const targetPosition = this.getDropdownPosition(rowElement)
      // unhide and position the target element
      this.dropdownContainer.classList.add('visible')
      this.dropdownPosition = { top: targetPosition.top + 'px', left: targetPosition.right + 'px' }
      if (this.dropdownPosition.top >= (window.innerHeight - 150)) {
        // if cell position is within X px of window bottom, above clicked cell
        this.dropdownPosition = { bottom: targetPosition.bottom + 'px', left: targetPosition.right + 'px' }
      }
    },
    hideDropdown () {
      this.dropdownContainer.classList.remove('visible')
    },
    getDropdownPosition (el) {
      const parent = el.parentElement
      const position = parent.getBoundingClientRect()
      return { right: position.right, top: position.top, bottom: position.bottom }
    },
    handleClickOutside (event) {
      const actionsDropdown = this.$refs.actionsDropdown
      const rowId = this.dropdownActionRowId
      const rowElement = `#row-${rowId}-actions`
      const dotParent = document.querySelector(rowElement)
      const subMenuDots = dotParent ? dotParent.querySelector(':scope .actions-kebab') : null
      const target = event.target
      if ((actionsDropdown && actionsDropdown !== target) && (subMenuDots && subMenuDots !== target)) {
        this.isDropdownVisible = false
      }
    },
    dischargeFromProgram (acp) {
      const params = {
        patientId: this.$getEncodedId(acp.access_control_id),
        accessControlProgramId: this.$getEncodedId(acp.access_control_program_id),
        assignmentId: this.$getEncodedId(acp.client_clinic_assignment_user_id),
        dischargingFromSection: 1
      }
      this.$router.push({ name: 'ClinicianDischargeFromProgram', params })
    },
    hideAndExecute (action) {
      this.isDropdownVisible = false
      this.setScrollPosition({ tab: this.homeTab, index: this.rowIndex })
      this[action](this.dropdownActionAcp)
    },
    headerHeightGetter () {
      const columnHeaderTexts = [
        ...document.querySelectorAll('.ag-header-cell-text')
      ]
      const clientHeights = columnHeaderTexts.map(
        headerText => headerText.clientHeight
      )
      const tallestHeaderTextHeight = Math.max(...clientHeights)

      return tallestHeaderTextHeight
    }
  }
}
</script>

<style lang="scss">
  .home-content {
    &.programs {
      .ag-row {
        .sub-menu-dots {
          padding: 0;
          display: none;
          i {
            padding: 4px 8px;
          }
        }
        &:hover {
          cursor: pointer;
          .sub-menu-dots {
            display: block;
          }
        }
      }

      .actions-dropdown-container {
        @apply tw-absolute tw-text-left tw-border tw-border-owl-navy tw-rounded-sm tw-bg-white tw-hidden tw-z-10;
        &.visible {
          @apply tw-block tw-w-72 tw-py-4;
        }
        a {
          @apply tw-text-lg tw-font-medium;
        }
        .sub-menu-dots-title {
          @apply tw-text-base tw-font-light tw-leading-7 tw-pl-4
        }
        .dropdown-item {
          @apply tw-leading-8 tw-p-0 tw-font-sans tw-font-medium tw-pl-4;
          &:hover {
            @apply tw-cursor-pointer tw-bg-owl-teal-lightest;
          }
          &.selected {
            @apply tw-font-sans tw-font-bold;
          }
        }
      }
    }
  }
  .programs-dropdown-container {
    @apply tw-flex tw-z-10 tw-bg-white tw-relative tw-py-4 tw-px-2.5;
    .loading {
      opacity: 40%;
    }
    .owl-dropdown-option {
      min-width: 256px;
      max-width: 520px;
    }
  }
  .ag-body-viewport.ag-layout-normal {
    overflow-y: overlay;
  }
  .ag-default-cell {
    overflow: visible;
  }
  .ag-row {
      z-index: 0;
  }
  .ag-row.ag-row-focus {
      z-index: 1;
  }
  .no-patients-link {
    -webkit-font-smoothing: antialiased;
    width: 865px;
    font-weight: 100;
    font-size: 15px;
    position: absolute;
    top: 573.5px;
    z-index: 10;
  }
</style>
